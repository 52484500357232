import React from 'react';

import {
  AttributesPolicySubForm,
  EventPolicySubForm,
  FacePolicySubForm,
  FaceSamplePolicySubForm,
  BodySamplePolicySubForm,
  NotificationPolicySubForm,
  CommonSubForm,
  EstimationsFaceSubForm,
  EstimationsBodySubForm,
  FiltersSubForm,
  MatchPolicySubForm,
  TagPolicySubForm,
  ImageOriginPolicySubForm,
  CallbacksPolicySubForm,
  PeopleCountSubForm,
  LivenessSubForm,
} from '@vlabs/pages/handlers/forms';
import { permissions } from '@vlabs/shared/config';
import { Page, Divider, Control, Grid, GridRow, GridCol, Fold } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { DeepfakeSubForm } from './forms/DeepfakeSubForm';
import FaceQualitySubForm from './forms/FaceQualitySubForm';

import './EditHandlerForm.sass';

export const EditHandlerFormComponent = ({
  onSubmit,
  values,
  handlerOptions,
  listOptions,
  can,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: values });
  const { handleSubmit } = formMethods;

  const onAction = (
    <Control.Button hasPermission={can(permissions.handler.modification)} type="submit" variant="outlined">
      {t('кнопка.сохранить')}
    </Control.Button>
  );

  const context = {
    ...formMethods,
    listOptions,
    handlerOptions,
  };

  return (
    <FormProvider {...context}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Page
          actions={onAction}
          title={t('handlers:edit page.title')}
        >
          <Divider />
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Fold
                  isOpen
                  title={t('handlers:setup sections.general')}
                >
                  <CommonSubForm />
                </Fold>
                <Divider />
                {!values.isDynamic && (
                  <>
                    <Fold
                      data-testid="faceAttributes"
                      isOpen
                      title={t('handlers:setup sections.determined face attributes')}
                    >
                      <EstimationsFaceSubForm />
                    </Fold>
                    <Divider />

                    <LivenessSubForm />
                    <Divider />

                    <DeepfakeSubForm />
                    <Divider />

                    <FaceQualitySubForm />
                    <Divider />

                    <Fold
                      data-testid="bodyAttributes"
                      isOpen
                      title={t('handlers:setup sections.determined body attributes')}
                    >
                      <EstimationsBodySubForm />
                    </Fold>
                    <Divider />

                    <PeopleCountSubForm />
                    <Divider />

                    <Fold
                      isOpen
                      title={t('handlers:setup sections.filters')}
                    >
                      <FiltersSubForm />
                    </Fold>
                    <Divider />

                    <Fold
                      isOpen
                      title={t('handlers:setup sections.labels')}
                    >
                      <MatchPolicySubForm />
                    </Fold>

                  </>
                )}

              </GridCol>

              {!values.isDynamic && (
                <GridCol cols={6}>
                  <FaceSamplePolicySubForm />
                  <Divider />
                  <BodySamplePolicySubForm />
                  <Divider />
                  {/* TODO: Разобраться с неймингом */}
                  <AttributesPolicySubForm />
                  <Divider />
                  <ImageOriginPolicySubForm />
                  <Divider />
                  <FacePolicySubForm />
                  <Divider />
                  <EventPolicySubForm />
                  <Divider />
                  <NotificationPolicySubForm />
                  <Divider />
                  <Fold
                    isOpen
                    title={t('handlers:setup sections.tagging parameters')}
                  >
                    <TagPolicySubForm />
                  </Fold>
                  <Divider />
                  <Fold
                    isOpen
                    title={t('handlers:setup sections.callbacks')}
                  >
                    <CallbacksPolicySubForm />
                  </Fold>
                </GridCol>
              )}
            </GridRow>
          </Grid>
        </Page>
      </form>
    </FormProvider>
  );
};

EditHandlerFormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  handlerOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  listOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

EditHandlerFormComponent.defaultProps = {
  values: undefined,
  handlerOptions: [],
  listOptions: [],
};

export const EditHandlerForm = connect((state) => ({
  can: viewerCan(state),
}))(EditHandlerFormComponent);
