import React, { useCallback } from 'react';

import { calcBadgeType } from '@vlabs/shared/utils';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { TopMatchEventCard } from './TopMatchEventCard';
import { TopMatchFaceCard } from './TopMatchFaceCard';

import './TopMatchCell.sass';

export const TopMatchCell = ({
  topMatch,
  onTopMatchCardClick,
}) => {
  const badgeColor = calcBadgeType(topMatch?.similarity);

  const clickHandler = useCallback((e) => {
    if (!onTopMatchCardClick) return;
    if (e.code === 'Enter' || e.code === undefined) {
      onTopMatchCardClick(e);
    }
  }, [onTopMatchCardClick]);

  if (!topMatch) return null;

  return (
    <div
      className={cn(
        'TopMatch_wrapper',
        'TopMatchCell',
        'TopMatch_flex',
        `TopMatchCard_color_${badgeColor}`,
      )}
      role="button"
      tabIndex="0"
    >
      {topMatch.eventType === 'face' && (
        <TopMatchFaceCard
          onTopMatchCardClick={clickHandler}
          topMatch={topMatch}
        />
      )}
      {topMatch.eventType === 'event' && (
        <TopMatchEventCard
          onEventClick={onTopMatchCardClick}
          topMatch={topMatch}
        />
      )}
    </div>
  );
};

TopMatchCell.propTypes = {
  topMatch: PropTypes.objectOf(PropTypes.any),
  onTopMatchCardClick: PropTypes.func,
};

TopMatchCell.defaultProps = {
  topMatch: undefined,
  onTopMatchCardClick: undefined,
};
