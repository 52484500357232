import { createSelector } from '@reduxjs/toolkit';
import { can } from '@vlabs/shared/utils';

export const selectAccount = (state) => state.account || {};

export const selectIsAuthenticated = createSelector(
  selectAccount,
  ({ isAuthenticated }) => isAuthenticated,
);
export const selectAccountModel = createSelector(
  selectAccount,
  ({ model }) => model,
);
export const selectAccountRole = createSelector(
  selectAccountModel,
  (model) => {
    // Сделано для проверки роли luna
    if (model?.account_type && model.account_type === 'admin') {
      return 2;
    }

    return model?.role || 0;
  },
);

// Email luna
export const selectAccountLogin = createSelector(
  selectAccountModel,
  (model) => (model ? model.login : null),
);

export const selectAccountEmail = createSelector(
  selectAccountModel,
  (model) => (model ? model.email : null),
);

const checkPermissions = (
  accountModel,
  requestedPermissions,
  isRequireAll = false, // Указывает, нужно ли, чтобы все разрешения были выполнены
) => {
  if (!accountModel.permissions) {
    // Проверка разрешений по роли, если permissions нет
    if (requestedPermissions.every((permission) => typeof permission === 'string')) {
      const checkFn = isRequireAll ? 'every' : 'some';
      return requestedPermissions[checkFn]((permission) => can(accountModel.role, permission));
    }
    return can(accountModel.role, ...requestedPermissions);
  }

  // Проверка разрешений по permissionsRaw, пока используется только в luna-ui
  const checkFn = isRequireAll ? 'every' : 'some';
  return requestedPermissions[checkFn]((permissionRule) => {
    const [feature, permission] = permissionRule.split(':');
    if (feature === 'emit_events') {
      return accountModel.permissionsRaw?.[feature]?.allowed === parseInt(permission, 10);
    }
    return accountModel.permissionsRaw?.[feature]?.includes(permission);
  });
};

export const viewerCan = createSelector(
  selectAccountModel,
  (accountModel) => (...requestedPermissions) => checkPermissions(accountModel, requestedPermissions),
);

export const viewerCanAllOf = createSelector(
  selectAccountModel,
  (accountModel) => (...requestedPermissions) => checkPermissions(accountModel, requestedPermissions, true),
);
