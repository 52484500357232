import React, { useEffect, useState, useMemo } from 'react';

import { ClockIcon, CircleInfoIcon, ListIcon, ArrowInCircleRightIcon } from '@vlabs/icons';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectListById } from '@vlabs/shared/selectors/listSelectors';
import { parseISOToLocale } from '@vlabs/shared/utils';
import { ButtonScrimGroup, ButtonScrimItem } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useSelector, shallowEqual } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import st from './FaceCard.module.sass';

const FaceCard = ({
  children,
  face,
  can,
}) => {
  const { t } = useTranslation();
  const [lists, setLists] = useState();

  const hasLPermission = can(permissions.list.view);
  const avatarUrl = face?.uiAvatarURL;
  const listIds = useMemo(() => face?.lists || [], [face?.lists]);
  const listsCount = listIds.length;
  const listsData = useSelector((state) => listIds.map((listId) => selectListById(listId)(state)), shallowEqual);

  useEffect(() => {
    if (hasLPermission) {
      setLists(listsData?.map((list) => list?.user_data).filter(Boolean).join(', '));
    } else {
      setLists(listIds.join(', '));
    }
  }, [hasLPermission, listsData, listIds]);

  return (
    <div
      className={st.Root}
      data-testid="faceCard"
    >
      <div
        className={st.PhotoContainer}
        data-testid="faceCard.photo"
      >
        <Sample className={st.Photo} image={avatarUrl} />
        {can(permissions.face.view) && face.face_id && (
          <ButtonScrimGroup position="top-right">
            <ButtonScrimItem
              as={Link}
              data-testid="faceCard.goToButton"
              icon={<ArrowInCircleRightIcon />}
              title={t('faces:детали лица')}
              to={generatePath(routes.facePage, { faceId: face.face_id })}
            />
          </ButtonScrimGroup>
        )}
      </div>

      <div className={st.MetaContainer}>
        {face?.user_data && (
          <div
            className={st.Row}
            title={`${t('information')}: ${face.user_data}`}
          >
            <div className={st.Icon}>
              <CircleInfoIcon className="StaticIcon" />
            </div>
            <div className={st.Row__Value}>
              {face.user_data}
            </div>
          </div>
        )}

        <div className={st.Row} title={t('date created')}>
          <ClockIcon className="StaticIcon" />
          <span>
            {parseISOToLocale(face.create_time)}
          </span>
        </div>

        {!!lists && (
          <div className={st.Row} title={t('списки')}>
            <div className={st.Icon}>
              <ListIcon className="StaticIcon" />
            </div>
            {`(${listsCount})`}
            &nbsp;
            <div className={st.Row__Value}>{lists}</div>
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

FaceCard.propTypes = {
  face: PropTypes.shape({
    lists: PropTypes.arrayOf(PropTypes.string),
    face_id: PropTypes.string,
    user_data: PropTypes.string,
    uiAvatarURL: PropTypes.string,
    create_time: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  can: PropTypes.func.isRequired,
};

FaceCard.defaultProps = {
  face: undefined,
  children: undefined,
};

const $FaceCard = connect((state) => ({
  can: viewerCan(state),
}))(FaceCard);

export { $FaceCard as FaceCard };
