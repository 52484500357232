import React, { useEffect, useContext } from 'react';

import { Sample } from '@vlabs/shared/components/sample/Sample';
import { GoToCell } from '@vlabs/shared/components/table';
import { EventActions } from '@vlabs/shared/legacy-components/action-button-groups/EventActions';
import { EventInfoCell } from '@vlabs/shared/legacy-components/event-info-cell/EventInfoCell';
import { Table, ButtonToggleGroup, ButtonToggle } from '@vlabs/uikit';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { SearchContext } from '../search-context';
import './Preview.sass';

const EventPreviewCell = (value) => (
  <Sample
    className={cn('PreviewImage', `PreviewImage_${value?.type}`)}
    data-testid="eventTableSample"
    {...value}
  />
);

export const EventPreview = () => {
  const { t } = useTranslation();
  const { referencesPreview, onMatcherTypeChange, matcherType, setMatcherType } = useContext(SearchContext);
  const reference = referencesPreview?.data?.[0];

  useEffect(() => {
    if (reference?.body_detections && reference?.body_detections?.length !== 0) {
      setMatcherType('body');
    }
    if (reference?.face_detections && reference?.face_detections?.length !== 0) {
      setMatcherType('face');
    }
    if (!reference?.face_detections && !reference?.body_detections) {
      setMatcherType('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference?.face_detections, reference?.body_detections]);

  const buildEventLink = (matchResult) => {
    return matchResult.event_id ? `/events/${matchResult.event_id}` : undefined;
  };

  const columns = [
    {
      id: 1,
      accessor: (event) => {
        const uiMainDetection = matcherType === 'body'
          ? event?.uiBodyDetection
          : event?.uiFaceDetection;

        return {
          type: matcherType,
          image: uiMainDetection?.uiSampleURL,
          controls: <EventActions
            imageOriginURL={event?.detectionWithUiFullFrame?.uiFullFrame}
            sampleURL={uiMainDetection?.uiSampleURL}
          />,
        };
      },
      Cell: ({ value }) => (matcherType ? EventPreviewCell(value) : null),
      width: 80,
    },
    {
      id: 2,
      // FIXME: непонятный компонент, либо это ячейка таблицы и она должна принимать как row, так и value
      // либо это отдельный компонент, который можно сделать частью *Cell компонента
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }) => <EventInfoCell {...row} />,
      width: 135,
    },
    {
      id: 3,
      Cell: GoToCell,
      accessor: buildEventLink,
      width: 1,
    },
  ];

  return (
    <div
      className={cn('EventPreview__Table_custom')}
      data-testid="table.referencesPreview"
    >
      <ButtonToggleGroup
        className="ButtonToggle"
        itemClassName="ButtonToggle__Item"
        onChange={onMatcherTypeChange}
        testId="buttonSubgroup"
        value={matcherType}
      >
        <ButtonToggle
          disabled={(matcherType === 'body' || matcherType === '') && !reference?.face_detections?.length}
          value="face"
        >
          {t('face')}
        </ButtonToggle>
        <ButtonToggle
          disabled={(matcherType === 'face' || matcherType === '') && !reference?.body_detections?.length}
          value="body"
        >
          {t('тело')}
        </ButtonToggle>
      </ButtonToggleGroup>

      <Table
        columns={columns}
        data={referencesPreview.data}
        noHead
      />
    </div>
  );
};
