import React from 'react';

import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { Control } from '@vlabs/uikit';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';
import { selectHandlerOptions } from '@vlabs/pages/handlers/selectors';

const HandlerLink = ({
  handlerId,
  handlerOptions,
  can,
}) => {
  const currentHandler = handlerOptions?.find((handler) => handler.value === handlerId);

  if (!currentHandler) {
    return <>{i18next.t('удалён')}</>;
  }

  return (
    <Control.Link
      data-testid={`HandlerLink${handlerId}`}
      disabled={!can(permissions.handler.view)}
      to={generatePath(routes.handlers.read, { handlerId })}
    >
      {currentHandler.label}
    </Control.Link>
  );
};

HandlerLink.propTypes = {
  handlerId: PropTypes.string,
  handlerOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  can: PropTypes.func.isRequired,
};
HandlerLink.defaultProps = {
  handlerId: undefined,
  handlerOptions: [],
};

export default connect(
  (state) => ({
    handlerOptions: selectHandlerOptions(state),
    can: viewerCan(state),
  }),
)(HandlerLink);
