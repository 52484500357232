import React, { useState } from 'react';

import { DetectionSelector } from '@vlabs/shared/components/detection-selector';
import { imageTypes, permissions } from '@vlabs/shared/config';
import {
  estimateISO,
  estimateDeepfake,
  estimateLiveness,
  estimateIcaoCompliance,
  estimateMindigitalCompliance,
} from '@vlabs/shared/requests';
import { selectIsAppFeatureAvailable, selectMaxImageSize } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Control, Divider, Page, ButtonToggleGroup, ButtonToggle, Panel } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';
import { selectDynamicHandlerId } from '@vlabs/pages/handlers/selectors';

import st from './ChecksPage.module.sass';
import {
  IsoCheckResults,
  IcaoCheckResults,
  MindigitalCheckResults,
  LivenessCheckResults,
  DeepfakeCheckResults,
} from './folds';

function ChecksPage({
  maxImageSize,
  dynamicHandlerId,
  isFaceQualityAvailable,
  isLivenessAvailable,
  isDeepfakeAvailable,
  can,
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [fileType, setFileType] = useState('image');
  const [checks, setChecks] = useState({
    liveness: false,
    deepfake: false,
    iso: false,
    icao: false,
    mindigital: false,
  });
  const [$faceDetections, $setFaceDetections] = useState([]);
  const [results, setResults] = useState({});
  const [multipleFacesError, setMultipleFacesError] = useState(false);

  const { handleSubmit, setError, reset, register, formState: { errors } } = useForm({
    defaultValues: { file: undefined },
  },
  );

  const onFileSelect = (newFile) => {
    if (!newFile) return;
    setFile(newFile);
  };

  const onReset = () => {
    reset({ file: undefined });
    setFile(undefined);
    setResults({});
    $setFaceDetections([]);
    setMultipleFacesError(false);
  };

  const makeRequest = async (estimateFunction, payload, type) => {
    try {
      const data = await estimateFunction(payload);
      return { type, data };
    } catch (error) {
      return error;
    }
  };

  const estimateImage = async ({ file: fileList }) => {
    const promises = [];
    // Создаем массив промисов для каждого запроса
    if (checks.iso) promises.push(makeRequest(estimateISO, fileList, 'iso'));
    if (checks.liveness) promises.push(makeRequest(estimateLiveness, fileList[0], 'liveness'));
    if (checks.deepfake) promises.push(makeRequest(estimateDeepfake, fileList[0], 'deepfake'));
    if (checks.icao) {
      promises.push(makeRequest(estimateIcaoCompliance, {
        file: fileList[0],
        handlerId: dynamicHandlerId,
      }, 'icao'));
    }
    if (checks.mindigital) {
      promises.push(makeRequest(estimateMindigitalCompliance, {
        file: fileList[0],
        handlerId: dynamicHandlerId,
      }, 'mindigital'));
    }

    // Ждем выполнения всех запросов
    const promiseResults = await Promise.all(promises);

    // Массивы для сбора ошибок
    const errorsInResults = promiseResults.filter((result) => result instanceof Error);
    const errorCode11038Error = errorsInResults.find((error) => error.error_code === 11038);
    const otherErrors = errorsInResults.filter((error) => error.error_code !== 11038);

    // Установка ошибки с кодом 11038
    if (errorCode11038Error && !multipleFacesError) {
      setMultipleFacesError(true);
      setError('file', { message: t('checks:error.multiple faces') });
    }

    // Если были другие ошибки помимо ошибки 11038, выбрасываем исходное исключение
    if (otherErrors.length > 0) {
      otherErrors.forEach((error) => {
        throw error;
      });
    }

    // Установка успешных результатов
    const successfulResults = promiseResults.filter((result) => !(result instanceof Error));
    setResults(successfulResults.reduce((acc, { data, type }) => {
      if (data) acc[type] = data;
      return acc;
    }, {}),
    );

    // Обработка успешных результатов
    successfulResults.forEach(({ data }) => {
      if (!data) return;
      if (data.estimations?.length === 0 || data.events?.length === 0) {
        setError('file', { message: t('выбранное изображение не содержит лиц') });
        setResults({});
        return;
      }
      if (data.estimations?.length > 0) {
        const faceDetections = data.estimations.map((face, index) => ({
          id: index,
          rect: face.face.detection.rect,
        }));
        $setFaceDetections(faceDetections);
      }
      if (data.detections?.length > 0) {
        const faceDetections = data.detections.map((face, index) => ({
          id: index,
          rect: face.samples.face.detection.rect,
        }));
        $setFaceDetections(faceDetections);
      }
    });
  };

  const actions = (
    <>
      {$faceDetections.length > 0 && (
        <Control.Button
          data-testid="photoFormResetButton"
          kind="negative"
          onClick={onReset}
          type="reset"
        >
          {t('кнопка.сбросить')}
        </Control.Button>
      )}

      {$faceDetections.length === 0 && (
        <Control.Button
          data-testid="photoFormSaveButton"
          disabled={Object.values(checks).every((value) => !value)}
          type="submit"
        >
          {t('checks:button.check')}
        </Control.Button>
      )}
    </>
  );

  const onChangeFileType = (value) => {
    reset({ file: undefined });
    setFile(undefined);
    setFileType(value);
  };

  return (
    <form onSubmit={handleSubmit(estimateImage)}>
      <Page
        actions={actions}
        title={$faceDetections.length > 0 ? t('checks:page.title.check_image') : null}
      >
        <Divider small />
        <div className={st.Root}>

          <div className={st.File}>
            {$faceDetections.length === 0 && (
              <ButtonToggleGroup
                className={st.ButtonToggle}
                itemClassName={st.ButtonToggle__Item}
                onChange={onChangeFileType}
                testId="buttonGroup"
                value={fileType}
              >
                <ButtonToggle
                  value="image"
                >
                  {t('checks:source type selector.image.title')}
                </ButtonToggle>
                <ButtonToggle
                  disabled
                  title={t('checks:source type selector.video.tooltip')}
                  value="video"
                >
                  {t('checks:source type selector.video.title')}
                </ButtonToggle>
              </ButtonToggleGroup>
            )}

            {fileType === 'image' && (
              <>
                {$faceDetections.length === 0 && (
                  <Control.FileDropzone
                    data-testid="photoFormFileSelector"
                    id="file"
                    onChangeValue={onFileSelect}
                    onReset={onReset}
                    {...register('file', {
                      required: !file && validate.required(),
                    })}
                    error={errors?.file && errors?.file.message}
                    hasImagePreview
                    maxImageSize={maxImageSize}
                    onlyImages
                    supportedImageTypes={imageTypes}
                  />
                )}
                {$faceDetections.length > 0 && (
                  <DetectionSelector
                    className={st.Count_center}
                    data-testid="fileSelector"
                    detections={$faceDetections}
                    error={errors?.file && errors?.file.message}
                    photo={file?.binary}
                  />
                )}
              </>
            )}
            {/* {fileType === 'video' && <VideoDropzoneTab />} */}
          </div>

          {$faceDetections.length === 0 && (
            <div className={st.Wrapper}>
              <span className={st.Header}>
                {`${t('checks:page.title.checks')}`}
              </span>
              <Panel>
                {isLivenessAvailable && (
                  <div className={st.Switch}>
                    <Control.Switch
                      hasPermission={can(permissions.resources.sdk)}
                      id="liveness"
                      label={t('checks:estimations.liveness.title')}
                      onChange={() => setChecks({ ...checks, liveness: !checks.liveness })}
                      value={checks.liveness}
                    />
                  </div>
                )}
                {isDeepfakeAvailable && (
                  <div className={st.Switch}>
                    <Control.Switch
                      hasPermission={can(permissions.resources.sdk)}
                      id="deepfake"
                      label={t('checks:estimations.deepfake.title')}
                      onChange={() => setChecks({ ...checks, deepfake: !checks.deepfake })}
                      value={checks.deepfake}
                    />
                  </div>
                )}
                {isFaceQualityAvailable && (
                  <>
                    <div className={st.Switch}>
                      <Control.Switch
                        hasPermission={can(permissions.resources.iso)}
                        id="iso"
                        label={t('checks:estimations.iso.title')}
                        onChange={() => setChecks({ ...checks, iso: !checks.iso })}
                        value={checks.iso}
                      />
                    </div>
                    <div className={st.Switch}>
                      <Control.Switch
                        hasPermission={can(permissions.emitEvents.allowed)}
                        id="icao"
                        label={t('checks:estimations.icao.title')}
                        onChange={() => setChecks({ ...checks, icao: !checks.icao })}
                        value={checks.icao}
                      />
                    </div>
                    <div className={st.Switch}>
                      <Control.Switch
                        hasPermission={can(permissions.emitEvents.allowed)}
                        id="mindigital"
                        label={t('checks:estimations.mindigital.title')}
                        onChange={() => setChecks({ ...checks, mindigital: !checks.mindigital })}
                        value={checks.mindigital}
                      />
                    </div>
                  </>
                )}
              </Panel>
            </div>
          )}

          {$faceDetections.length > 0 && (
            <div className={st.Results}>
              {results.liveness && (
                <LivenessCheckResults
                  results={results.liveness}
                />
              )}
              {results.deepfake && (
                <DeepfakeCheckResults
                  results={results.deepfake}
                />
              )}
              {results.iso && (
                <IsoCheckResults
                  results={results.iso}
                />
              )}
              {results.icao && (
                <IcaoCheckResults
                  results={results.icao}
                />
              )}
              {results.mindigital && (
                <MindigitalCheckResults
                  results={results.mindigital}
                />
              )}
            </div>
          )}
        </div>
      </Page>
    </form>
  );
}

ChecksPage.propTypes = {
  can: PropTypes.func.isRequired,
  maxImageSize: PropTypes.number,
  dynamicHandlerId: PropTypes.string,
  isDeepfakeAvailable: PropTypes.bool,
  isFaceQualityAvailable: PropTypes.bool,
  isLivenessAvailable: PropTypes.bool,
};

ChecksPage.defaultProps = {
  maxImageSize: undefined,
  dynamicHandlerId: undefined,
  isFaceQualityAvailable: false,
  isDeepfakeAvailable: false,
  isLivenessAvailable: false,
};

export default connect((state) => ({
  can: viewerCan(state),
  maxImageSize: selectMaxImageSize(state),
  dynamicHandlerId: selectDynamicHandlerId(state),
  isFaceQualityAvailable: selectIsAppFeatureAvailable(state, 'estimations.face_quality'),
  isLivenessAvailable: selectIsAppFeatureAvailable(state, 'estimations.liveness'),
  isDeepfakeAvailable: selectIsAppFeatureAvailable(state, 'estimations.deepfake'),
}))(ChecksPage);
