import React, { useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { optionPropType } from '@vlabs/shared';
import { permissions } from '@vlabs/shared/config';
import { Control, useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { viewerCanAllOf } from '@vlabs/pages/auth/selectors';

import { FaceCreateForm } from './forms/FaceCreateForm';

// FIXME: переводы сдлеать
const CreateFaceWidgetComponent = ({
  defaultValues,
  photoUploadComponent,
  formFieldComponent,
  afterSubmit,
  title,
  canAllOf,
}) => {
  const { t } = useTranslation();
  const modal = useModal();

  const submitForm = useCallback(async (values) => {
    await apiContainer.lunaClient.faces.create(values, { extract_basic_attributes: 1 });
    if (afterSubmit) afterSubmit();
    modal.close();
    toast.success(t('faces:создание.успех'));
  }, [afterSubmit, modal, t]);

  return (
    <>
      {modal.wrap(<FaceCreateForm
        defaultValues={defaultValues}
        formFieldComponent={formFieldComponent}
        onSubmit={submitForm}
        photoUploadComponent={photoUploadComponent}
      />)}
      <Control.Button
        data-testid="createFaceButton"
        hasPermission={canAllOf(permissions.face.creation,
          permissions.resources.sdk,
          permissions.faceSample.creation,
          permissions.attribute.creation,
        )}
        onClick={modal.open}
      >
        {title || t('externalID:action.create face')}
      </Control.Button>
    </>
  );
};

CreateFaceWidgetComponent.propTypes = {
  afterSubmit: PropTypes.func,
  canAllOf: PropTypes.func.isRequired,
  photoUploadComponent: PropTypes.node,
  formFieldComponent: PropTypes.node,
  defaultValues: PropTypes.shape({
    external_id: PropTypes.string,
    lists: PropTypes.arrayOf(optionPropType),
  }),
  title: PropTypes.string,
};

CreateFaceWidgetComponent.defaultProps = {
  defaultValues: {},
  afterSubmit: undefined,
  photoUploadComponent: undefined,
  formFieldComponent: undefined,
  title: undefined,
};

export const CreateFaceWidget = connect((state) => ({
  canAllOf: viewerCanAllOf(state),
}))(CreateFaceWidgetComponent);
