import React from 'react';

import { SearchIcon } from '@vlabs/icons';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import st from './FaceActions.module.sass';

export function FaceActionsComponent({ faceId, onEditPhoto, onFaceDelete, onEditFaceInfo, can }) {
  const { t } = useTranslation();

  return (
    <div className={st.Root}>
      <div>
        <Control.Link
          data-testid="faceActionsSearchLink"
          to={generatePath(`${routes.search}?${qs.stringify({ references: [{
            id: faceId,
            type: 'face',
            referenceType: 'face',
            candidateOrigin: 'faces',
          }] })}`)}
        >
          {t('faces:найти схожие лица')}
          <SearchIcon className={st.Icon} />
        </Control.Link>
      </div>

      <Divider small />

      <div className={st.ButtonContainer}>
        <Control.Button
          data-testid="faceActionsUpdateButton"
          fullWidth
          onClick={onEditPhoto}
          variant="outlined"
        >
          {t('faces:обновить фотографию')}
        </Control.Button>
        <Divider small />

        <Control.Button
          data-testid="faceActionsEditButton"
          fullWidth
          onClick={onEditFaceInfo}
          variant="outlined"
        >
          {t('faces:редактировать данные')}
        </Control.Button>
        <Divider small />

        {can(permissions.face.deletion) && (
          <Control.Button
            data-testid="faceActionsDeleteButton"
            fullWidth
            kind="negative"
            onClick={onFaceDelete}
            variant="outlined"
          >
            {t('faces:удалить лицо')}
          </Control.Button>
        )}
      </div>
    </div>
  );
}

FaceActionsComponent.propTypes = {
  faceId: PropTypes.string,
  onEditPhoto: PropTypes.func,
  onFaceDelete: PropTypes.func,
  onEditFaceInfo: PropTypes.func,
  can: PropTypes.func.isRequired,
};

FaceActionsComponent.defaultProps = {
  faceId: '',
  onEditPhoto: undefined,
  onFaceDelete: undefined,
  onEditFaceInfo: undefined,
};

export const FaceActions = connect((state) => ({
  can: viewerCan(state),
}))(FaceActionsComponent);
