// плюрализация для русского
//
// one ---- 1 день -- *1
// few ---- 4 дня --- *2-4
// many --- 5 дней -- *11-14 и остальное
// other -- 1.5 дня --- дробные

export const ru = {
  заголовки: {
    'страница не найдена': 'Страница не найдена',
    'недостаточно прав для просмотра раздела': 'Недостаточно прав для просмотра раздела',
  },
  валидация: {
    required: 'Поле обязательно для заполнения',
    email: 'Неверный формат email',
    'одинаковые пароли': 'Пароли не совпадают',
    'неподходящий формат файла': 'Неподходящий формат файла',
    число: {
      'больше или равно': 'Значение должно быть больше или равно {{value}}',
      'меньше или равно': 'Значение должно быть меньше или равно {{value}}',
      больше: 'Значение должно быть больше {{value}}',
      меньше: 'Значение должно быть меньше {{value}}',
      'только числа': 'Допускается ввод только целых и дробных чисел, разделенных знаком "." ',
      целое: 'Допустимы только целые числа',
    },
    длина: {
      максимум_one: 'Не более {{count}} символа',
      максимум_few: 'Не более {{count}} символов',
      максимум_many: 'Не более {{count}} символов',
      максимум_other: 'Не более {{count}} символов',
      минимум_one: 'Не менее {{count}} символа',
      минимум_few: 'Не менее {{count}} символов',
      минимум_many: 'Не менее {{count}} символов',
      минимум_other: 'Не менее {{count}} символов',
      'максимум для каждого id': 'Не более {{count}} символов для каждого идентификатора',
    },
    'пароли не совпадают': 'Пароли не совпадают',
    uuid: 'При ручном вводе допустим только UUID формат',
    roi: 'Введите четыре целых числа через запятую',
    'недопустимые значения': 'Недопустимые значения',
    regex: 'Не соответствует паттерну {{regex}}',
    uri: 'Неверный формат URI',
  },
  формы: {
    поля: {
      логин: 'Логин',
      email: 'Email',
      пароль: 'Пароль',
      'новый пароль': 'Новый пароль',
      'подтверждение нового пароля': 'Подвердите пароль',
    },
    действия: {
      вход: 'Войти',
      выход: 'Выйти',
      'переход к восстановлению пароля': 'Забыл пароль',
      'сохранение нового пароля': 'Сохранить',
      'восстановление пароля': 'Восстановить',
    },
  },
  control: {

    file: {
      плейсхолдер: 'Выбрать файл',
      'сообщение для дропа файла': 'Бросьте файл здесь',
    },
    select: {
      'создать опцию': 'Создать "{{value}}"',
      плейсхолдер: 'Выбрать...',
      'сообщение об отсутствии опций': 'Нет опций для выбора',
    },
    fileDropzone: {
      заголовок: 'Щёлкните или перетащите файл',
      подзаголовок: 'Фотография или пакет фотографий для идентификации (jpg, png, zip)',
      'выбрано изображение': 'Выбрано изображение',
      'выбран архив': 'Выбран архив',
      ошибки: {
        'неподходящий формат изображения': 'Неподходящий формат изображения',
        'неподходящий формат архива': 'Неподходящий формат архива',
        'неподходящий формат файла': 'Неподходящий формат файла',

        'неподходящий размер архива': 'Архив должен быть не больше\u00A0{{size}}\u00A0Гб',
        'неподходящий размер изображения': 'Изображение должно быть не больше\u00A0{{size}}\u00A0Мб',
      },
    },
    coordinatesInput: {
      modal: {
        заголовок: 'Координаты области',
      },
      'подсказка с превью': 'Настройку параметра можно выполнить визуально на изображении кадра',
      'подсказка без превью': 'Для настройки параметра необхдимо изображение кадра',
    },
    btn: {
      'недостаточно прав': {
        title: 'Недостаточно прав',
      },
    },
  },
  table: {
    'сообщение пустой таблицы': 'Данные отсутствуют',
    фильтры: {
      от: 'от',
      до: 'до',
      все: 'Все',
    },
    действия: {
      'удаление выбранных элементов': 'Удалить',
      'добавление нового элемента': 'Добавить',
      'удаление элемента': 'Удалить',
      'редактирование элемента': 'Редактировать',
      экспорт: 'Экспортировать',
      импорт: 'Импортировать',
    },
  },
  cam: {
    'сообщение об обсутствии изображения': 'Изображение отсутсвует',
  },
  popup: {
    действия: {
      отмена: 'Отмена',
      подтверждение: 'Ок',
      удаление: 'Удалить',
    },
  },
  copyButton: {
    успех: 'Скопировано',
  },
  faceCard: {
    поля: {
      пол: 'Пол',
      возраст: 'Возраст',
      'luna id': 'LUNA ID',
      'external id': 'Внешний ID',
    },
    'сообщение об отсутствии фотографии': 'Фотография отсутствует',
  },
  faceSelector: {
    'сообщение об отсутствии лиц на изображении': 'На изображении нет лиц',
    действия: {
      'выбор лица': 'Выбрать',
    },
  },
  imagesPointsMatcher: {
    'подсказка по управлению': 'Ctrl + Клик (Cmd + клик) для создания. Двойной клик для удаления.',
  },
  listSelector: {
    заголовок: 'В списках',
    действия: {
      'выбор всех списков': 'Выбрать все',
    },
  },
  polygonSelector: {
    'подсказка по завершению построения': 'Для завершения построения полигона нажмите на начальную точку',
    действия: {
      сохранение: 'Сохранить',
      сброс: 'Сбросить',
      удаление: 'Удалить',
    },
  },
  stepper: {
    шаг: 'Шаг {{count}}',
    действия: {
      'переход на шаг назад': 'Назад',
      'переход на шаг вперед': 'Далее',
    },
  },
  languageSelector: {
    lang: {
      ru: 'рус',
      en: 'eng',
    },
  },
  'images-points-canvas': {
    state: {
      init: 'Загружаются изображения',
    },
  },
  roi: {
    'размер кадра': 'Размер кадра',
    действия: {
      сохранение: 'Сохранить',
      'полный кадр': 'Полный кадр',
    },
  },
  errorToasts: {
    error: {
      title: 'Неизвестная ошибка',
      message: {
        label: 'Message:',
      },
      stack: {
        label: 'Stack:',
      },
    },
    axios: {
      title: 'Ошибка обращения к {{service}}',
      http: {
        status: {
          label: 'HTTP Status:',
        },
        method: {
          label: 'HTTP Method:',
        },
        requestUrl: {
          label: 'Request URL:',
        },
      },
      response: {
        label: 'Response:',
      },
    },
    luna: {
      error_code: {
        label: 'Код ошибки:',
      },
      desc: {
        label: 'Описание ошибки',
      },
      detail: {
        label: 'Детали ошибки',
      },
      link: {
        label: 'Ссылка на документацию',
      },
    },
    copyButtonLabel: 'Скопировать информацию об ошибке',
  },
};

export const kitRuTranslations = {
  uikit: ru,
};
