import React, { useContext } from 'react';

import { Sample } from '@vlabs/shared/components/sample/Sample';
import { GoToCell } from '@vlabs/shared/components/table';
import { FaceAction } from '@vlabs/shared/legacy-components/action-button-groups/FaceAction';
import FaceInfoCell from '@vlabs/shared/legacy-components/face-info-cell/FaceInfoCell';
import { Table } from '@vlabs/uikit';

import { SearchContext } from '../search-context';

import './Preview.sass';

const FacePreviewSampleCell = ({ value }) => (
  <Sample
    className="PreviewImage"
    data-testid="eventTableSample"
    {...value}
  />
);

const FacePreviewInfoCell = ({ row }) => <FaceInfoCell {...row} />;

export const FacePreview = () => {
  const buildFaceLink = (matchResult) => {
    return matchResult.face_id ? `/faces/${matchResult.face_id}` : undefined;
  };
  const { referencesPreview, referenceType, setPreviewPageIndex } = useContext(SearchContext);

  const columns = [
    {
      id: 1,
      accessor: (face) => ({
        image: face?.uiAvatarURL,
        controls: <FaceAction imageUrl={face?.uiAvatarURL} />,
      }),
      Cell: FacePreviewSampleCell,
      width: 80,
    },
    {
      id: 2,
      Cell: FacePreviewInfoCell,
      width: 135,
    },
    {
      id: 3,
      Cell: GoToCell,
      accessor: buildFaceLink,
      width: 1,
    },
  ];

  return (
    <div
      className="EventPreview__Table_custom"
      data-testid="table.facePreview"
    >
      {referenceType === 'face_external_id' && (
        <Table
          columns={columns}
          data={referencesPreview.data}
          meta={{ count: referencesPreview.count }}
          noHead
          pageIndex={referencesPreview.pageIndex}
          pageSize={2}
          pageSizeOptions={[2]}
          paginationType="offsetBased"
          setPageIndex={setPreviewPageIndex}
        />
      )}
      {referenceType === 'face' && (
        <Table
          columns={columns}
          data={referencesPreview.data}
          noHead
        />
      )}
    </div>
  );
};
