import React from 'react';

import { MatchTypeImage } from '@vlabs/shared/assets/images';
import { EventInfoCell } from '@vlabs/shared/legacy-components/event-info-cell/EventInfoCell';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import { calcSimilarityPercent, calcBadgeType } from '@vlabs/shared/utils';
import { Badge } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';

import { EventSample } from './EventSample';

import './TopMatchCell.sass';

export const TopMatchEventCard = ({
  topMatch,
  onEventClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="TopMatchSample__Wrapper">
        <EventSample event={topMatch} eventType="face" onEventClick={onEventClick} />
        <EventSample event={topMatch} eventType="body" onEventClick={onEventClick} />
      </div>

      <div className="InfoRow_wrapper">
        <Badge
          className="TopMatch__SimilarityBadge"
          data-testid="topMatchCell.lists"
          kind={calcBadgeType(topMatch?.similarity)}
          value={calcSimilarityPercent(topMatch?.similarity)}
        />
        <InfoRow
          columnDirection
          data-testid="topMatchCell.externalId"
          icon={<img alt="match type icon" className="StaticIcon" src={MatchTypeImage} />}
          title={t('events:match type')}
          value={t('event')}
        />
        <EventInfoCell original={topMatch} />
      </div>
    </>
  );
};
