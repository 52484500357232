import React from 'react';

import { ArrowInCircleRightIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const GoToCell = ({
  value,
  buttonProps,
}) => {
  if (value === undefined) return null;

  return (
    <Control.LinkButton
      as={RouterLink}
      data-testid="GoToCell"
      icon={<ArrowInCircleRightIcon />}
      kind="primary"
      round
      title={i18n.t('перейти')}
      to={value}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

GoToCell.propTypes = {
  value: PropTypes.string,
  buttonProps: PropTypes.object,
};

GoToCell.defaultProps = {
  value: '',
  buttonProps: undefined,
};

export { GoToCell };
