import React from 'react';

import { CalendarIcon, ListIcon, FolderIcon } from '@vlabs/icons';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import ListLink from '@vlabs/shared/legacy-components/list-link/ListLink';
import { parseISOToLocale } from '@vlabs/shared/utils';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import st from './FaceInfoCell.module.sass';

export default function FaceInfoCell({
  original,
  className,
}) {
  const { t } = useTranslation();
  return (
    <div className={cn(st.RowInfoWidth, className)}>
      <InfoRow
        columnDirection
        data-testid="faceInfoCell.createTime"
        icon={<CalendarIcon className="StaticIcon" />}
        title={t('date created')}
        value={parseISOToLocale(original?.create_time)}
      />
      <InfoRow
        columnDirection
        data-testid="faceInfoCell.userData"
        icon={<FolderIcon className="StaticIcon" />}
        title={t('information')}
        value={original?.user_data}
      />
      {!!original?.lists?.length && (
        <InfoRow
          columnDirection
          data-testid="faceInfoCell.lists"
          icon={<ListIcon className="StaticIcon" />}
          title={`${t('списки')} (${original?.lists?.length})`}
          value={<ListLink lists={original?.lists} />}
        />
      )}
    </div>
  );
}
