import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import i18n from 'app/translations/i18n';
import { toast } from 'react-toastify';

const store = createSlice({
  name: 'lists',
  initialState: {
    state: undefined,
    byId: {},
    allIds: [],
    listOptions: [],
  },
  reducers: {
    getListsSuccess(state, { payload }) {
      const lists = payload;
      state.allIds = [];
      state.options = [];
      state.byId = {};
      lists.forEach((list) => {
        state.allIds.push(list.list_id);
        state.options.push({ value: list.list_id, label: list.user_data });
        state.byId[list.list_id] = {
          ...list,
        };
      });
      state.listOptions = payload.map(({ list_id, user_data }) => ({
        value: list_id,
        label: user_data,
      }));
      state.state = 'loaded';
    },
    createListSuccess(state, { payload }) {
      state.allIds.unshift(payload.list_id);
      state.byId[payload.list_id] = payload;
      state.listOptions.unshift({ value: payload.list_id, label: payload.user_data });
    },
    updateListSuccess(state, { payload }) {
      state.byId[payload.list_id] = payload;
      const listIndex = state.listOptions.findIndex(({ value }) => value === payload.list_id);
      state.listOptions[listIndex].label = payload.user_data;
    },
    deleteListsSuccess(state, { payload }) {
      const { listIds } = payload;
      listIds.forEach((listId) => {
        state.allIds.splice(state.allIds.indexOf(listId), 1);
        delete state.byId[listId];
        state.listOptions.splice(state.listOptions.findIndex(({ value }) => value === listId), 1);
      });
    },
    setLists(state, { payload }) {
      state.listOptions = payload.map(({ list_id, user_data }) => ({
        value: list_id,
        label: user_data,
      }));
      state.state = 'loaded';
    },
  },
});

export default store.reducer;

export const {
  getListsSuccess,
  createListSuccess,
  updateListSuccess,
  deleteListsSuccess,
} = store.actions;

export const fetchLists = async (dispatch) => {
  const { data: { lists } } = await apiContainer.lunaClient.lists.getAll({ page_size: 100 });
  dispatch(getListsSuccess(lists));
};

export const fetchListsList = ({ page = 0, pageSize, ...params }) => apiContainer.lunaClient.lists
  .getAll({ page: page + 1, page_size: pageSize, ...params })
  .then(({ data: { lists: list } }) => ({ data: list }));

export const createList = ({ user_data }) => async (dispatch) => {
  const { data: { list_id } } = await apiContainer.lunaClient.lists.create({ user_data });
  toast.success(i18n.t('lists:подтверждение.создание.успех', { list: user_data }));
  const { data } = await apiContainer.lunaClient.lists.get(list_id);
  dispatch(createListSuccess(data));
};

export const updateList = ({ list_id, user_data }) => async (dispatch) => {
  await apiContainer.lunaClient.lists.update(list_id, { user_data });
  toast.success(i18n.t('lists:подтверждение.обновление.успех', { list: user_data }));
  const { data } = await apiContainer.lunaClient.lists.get(list_id);
  dispatch(updateListSuccess(data));
};

export const getFaceCountInList = async (listId) => apiContainer.lunaClient.faces.count({ list_id: listId });
export const updateFacesList = async (id, params) => apiContainer.lunaClient.lists.updateFaces(id, params);

export const deleteList = (listIds) => async (dispatch) => {
  await apiContainer.lunaClient.lists.delete(listIds, { with_faces: 1 });
  toast.success(i18n.t('lists:подтверждение.удаление.успех'));

  dispatch(deleteListsSuccess({ listIds }));
};

export const deleteLists = (listIds) => async (dispatch) => {
  await apiContainer.lunaClient.lists.deleteMany(listIds, { with_faces: 1 });
  toast.success(i18n.t('lists:подтверждение.удаление.успех'));

  dispatch(deleteListsSuccess({ listIds }));
};

export const deleteListWithoutFaces = (listIds) => async (dispatch) => {
  await apiContainer.lunaClient.lists.delete(listIds, { with_faces: 0 });
  toast.success(i18n.t('lists:подтверждение.удаление.успех'));

  dispatch(deleteListsSuccess({ listIds }));
};

export const deleteListsWithoutFaces = (listIds) => async (dispatch) => {
  await apiContainer.lunaClient.lists.deleteMany(listIds, { with_faces: 0 });
  toast.success(i18n.t('lists:подтверждение.удаление.успех'));

  dispatch(deleteListsSuccess({ listIds }));
};
