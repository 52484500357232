import React, { useState, useCallback } from 'react';

import { optionPropType } from '@vlabs/shared';
import { Control, Divider, openConfirmPopup, Page } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { buildMatchList } from './buildMatchList';
import st from './FaceCreateForm.module.sass';
import { IsoQualityCheckForm } from './IsoQualityCheckForm';
import { PhotoUploadForm } from './PhotoUploadForm';

export const FaceCreateForm = ({
  photoUploadComponent = <PhotoUploadForm />,
  formFieldComponent = <IsoQualityCheckForm />,
  onSubmit,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const [sampleId, setSampleId] = useState([]);
  const [detections, setDetections] = useState([]);
  const [isoResult, setIsoResult] = useState();
  const [shouldCheckISO, setShouldCheckISO] = useState();
  const form = useForm({ defaultValues });

  const onResetFile = useCallback(() => {
    form.clearErrors('file');
    form.setValue('file', '');
    setDetections([]);
    setSampleId([]);
  }, [form]);

  const createFace = useCallback(async (values) => {
    if (!sampleId.length) {
      form.setError('file', { message: t('faces:необходимо выбрать хотя бы одно лицо') });
      return;
    }

    if (values.external_id || values.lists.length) {
      const matchList = await buildMatchList({ formValues: values, detections, sampleId });
      if (matchList) {
        openConfirmPopup({
          title: t('faces:face creation.duplicate photos'),
          message: matchList,
          type: 'warning',
          onConfirm: () => onSubmit({ ...values, sample_id: sampleId }),
          onCancel: onResetFile,
        });
        return;
      }
    }

    onSubmit({ ...values, sample_id: sampleId });
  }, [onSubmit, sampleId, detections, form, t, onResetFile]);

  const context = {
    detections,
    setDetections,
    setSampleId,
    sampleId,
    isoResult,
    setIsoResult,
    shouldCheckISO,
    setShouldCheckISO,
    onResetFile,
  };

  return (
    <FormProvider form={form} {...context}>
      <form onSubmit={form.handleSubmit(createFace)}>
        <Page className={st.Root}>
          <h5>{t('faces:форма создания.заголовок')}</h5>
          <Divider small />

          <div className={st.ColumnsContainer}>
            <div className={st.PhotoUploadColumn}>
              {photoUploadComponent}
            </div>
            <div className={st.FormFieldColumn}>
              {formFieldComponent}
            </div>
          </div>

          <Divider small />
          <Control.Button
            disabled={!isoResult?.status && shouldCheckISO}
            fullWidth
            type="submit"
          >
            {t('форма.submit')}
          </Control.Button>
        </Page>
      </form>
    </FormProvider>
  );
};

FaceCreateForm.propTypes = {
  photoUploadComponent: PropTypes.node.isRequired,
  formFieldComponent: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    external_id: PropTypes.string,
    lists: PropTypes.arrayOf(optionPropType),
  }),
};

FaceCreateForm.defaultProps = {
  defaultValues: {},
};
