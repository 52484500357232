import React from 'react';

import { ZoomInIcon } from '@vlabs/icons';
import { ButtonScrimGroup, ButtonScrimItem } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FaceAction = ({
  imageUrl,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonScrimGroup position="bottom-center">
      <ButtonScrimItem
        as={imageUrl ? 'a' : 'div'}
        disabled={!imageUrl}
        href={imageUrl}
        icon={<ZoomInIcon />}
        title={t('посмотреть в полном размере')}
      />
    </ButtonScrimGroup>
  );
};

FaceAction.propTypes = {
  imageUrl: PropTypes.string,
};
FaceAction.defaultProps = {
  imageUrl: undefined,
};

export { FaceAction };
