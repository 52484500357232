import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import { GoToCell } from '@vlabs/shared/components/table';
import { EventActions } from '@vlabs/shared/legacy-components/action-button-groups/EventActions';
import { EventInfoCell } from '@vlabs/shared/legacy-components/event-info-cell/EventInfoCell';
import { routes } from '@vlabs/shared/navigation/routes';
import { Table, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { updateFilters } from '@vlabs/pages/events/events/store';

import { selectEventsWithTheSameFaceId } from '../selectors';
import { updateEventsPage } from '../store';
import st from './tabs.module.sass';

const Info = ({ title }) => (
  <div className={st.InfoRoot}>
    <CircleInfoIcon className="StaticIcon" />
    <div className={st.Title}>{title}</div>
  </div>
);

const EventSampleCell = ({ value }) => (
  <Sample
    className={st.Image}
    data-testid="eventTableSample"
    {...value}
  />
);

function EventsTableTab({
  face_id,
  events,
  setPageIndex,
  setEventsFilters,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const buildEventLink = (result) => (result.event_id ? `/events/${result.event_id}` : undefined);

  return (
    <div className={st.TablePanel}>
      {events.count === 0 && (
        <Info title={t('faces:события с данным лицом отсутствуют')} />
      )}

      {events.count > 0 && (
        <>
          <Table
            columns={[
              {
                id: 1,
                accessor: (value) => ({
                  image: value?.uiFaceDetection?.uiSampleURL,
                  similarity: value?.top_match?.similarity,
                  controls: <EventActions
                    imageOriginURL={value?.detectionWithUiFullFrame?.uiFullFrame}
                    sampleURL={value?.uiFaceDetection?.uiSampleURL}
                  />,
                }),
                Cell: EventSampleCell,
                width: 70,
              },
              {
                id: 2,
                // FIXME: непонятный компонент, либо это ячейка таблицы и она должна принимать как row, так и value
                // либо это отдельный компонент, который можно сделать частью *Cell компонента
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ row }) => (
                  <EventInfoCell
                    className={st.RowInfoWidth}
                    {...row}
                  />
                ),
                width: 170,
              },
              {
                id: 3,
                Cell: GoToCell,
                accessor: buildEventLink,
                width: 10
              },
            ]}
            data={events?.data}
            data-testid="eventTableTab"
            meta={{ count: events.count }}
            noHead
            pageIndex={events.pageIndex}
            pageSize={3}
            pageSizeOptions={[3]}
            paginationType="offsetBased"
            setPageIndex={setPageIndex}
          />

          {events.count > 1 && (
            <div className={st.GoTo}>
              <Control.Button
                data-testid="eventTableButton"
                onClick={() => {
                  setEventsFilters({ top_similar_object_ids: face_id });
                  history.push(generatePath(routes.events.archive));
                }}
                variant="outlined"
              >
                {t('faces:посмотреть все события с данным лицом')}
              </Control.Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

EventsTableTab.propTypes = {
  face_id: PropTypes.string,
  events: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    pageIndex: PropTypes.number,
    count: PropTypes.number,
  }),
  setPageIndex: PropTypes.func,
  setEventsFilters: PropTypes.func,
};

EventsTableTab.defaultProps = {
  face_id: '',
  events: {},
  setPageIndex: undefined,
  setEventsFilters: undefined,
};

const $EventsTableTab = connect(
  (state) => ({
    events: selectEventsWithTheSameFaceId(state),
  }),
  (dispatch) => ({
    setPageIndex: (pageIndex) => dispatch(updateEventsPage({ pageIndex })),
    setEventsFilters: (filters) => dispatch(updateFilters({ filters })),
  }),
)(EventsTableTab);

export { $EventsTableTab as EventsTableTab };
