// eslint-disable-next-line import/prefer-default-export
import { createSelector } from '@reduxjs/toolkit';

export const selectFace = (state) => state.facePage;

export const selectFaceId = createSelector(
  selectFace,
  ({ faceId }) => (faceId),
);
export const selectFacePageState = createSelector(
  selectFace,
  ({ state }) => (state),
);

export const selectFaceWithAttributes = createSelector(
  selectFace,
  ({ face, attributes, details, iso }) => ({
    ...face,
    ...attributes,
    ...details,
    iso,
  }),
);

const trimByPage = ({ data, pageSize, pageIndex }) => {
  const trimStart = pageIndex * pageSize;
  const trimEnd = trimStart + pageSize;
  return {
    data: data?.slice(trimStart, trimEnd),
    count: data?.length || 0,
    pageIndex,
  };
};

export const facesWithTheSameExternalIdSettings = createSelector(
  selectFace,
  ({ facesWithTheSameExternalId }) => (facesWithTheSameExternalId),
);
export const selectFacesWithTheSameExternalId = createSelector(
  facesWithTheSameExternalIdSettings,
  trimByPage,
);

const eventsWithTheSameFaceIdSettings = (state) => state.facePage.eventsWithTheSameFaceId;
export const selectEventsWithTheSameFaceId = createSelector(
  eventsWithTheSameFaceIdSettings,
  trimByPage,
);
