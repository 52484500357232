import React from 'react';

import { ImageIcon, ZoomInIcon, MoreHorizontalIcon } from '@vlabs/icons';
import { ButtonScrimGroup, ButtonScrimItem } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EventActions = ({
  modal,
  detections,
  sampleURL,
  imageOriginURL,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonScrimGroup position="bottom-center">
      <ButtonScrimItem
        as={sampleURL ? 'a' : 'button'}
        disabled={!sampleURL}
        href={sampleURL}
        icon={<ZoomInIcon />}
        title={t('посмотреть в полном размере')}
      />

      {modal && (
        <ButtonScrimItem
          data-testid="eventActions.viewTrackButton"
          disabled={!detections}
          icon={<MoreHorizontalIcon />}
          onClick={modal.open}
          title={t('просмотр трека')}
        />
      )}

      <ButtonScrimItem
        data-testid="eventActions.fullFrameButton"
        disabled={!imageOriginURL}
        icon={<ImageIcon />}
        onClick={() => imageOriginURL && window.open(imageOriginURL)}
        title={t('полный кадр')}
      />
    </ButtonScrimGroup>
  );
};

EventActions.propTypes = {
  modal: PropTypes.objectOf(PropTypes.any),
  detections: PropTypes.arrayOf(PropTypes.any),
  sampleURL: PropTypes.string,
  imageOriginURL: PropTypes.string,
};

EventActions.defaultProps = {
  modal: undefined,
  detections: undefined,
  sampleURL: undefined,
  imageOriginURL: undefined,
};

export { EventActions };
