import React, { useCallback } from 'react';

import { DEEPFAKE_MODE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { CircleInfoIcon } from '@vlabs/icons';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper, Fold, Tooltip } from '@vlabs/uikit';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useUpdateAllPoliciesField } from '../hooks';
import st from './FaceQualitySubForm.module.sass';

const DeepfakeSubFormComponent = ({
  isDeepfakeAvailable,
}) => {
  const { t } = useTranslation();
  const { control, register } = useFormContext();
  const { errors } = useFormState();
  const estimateDeepfake = useWatch({ name: 'estimate_deepfake.estimate' });

  useUpdateAllPoliciesField({
    on: useCallback(() => estimateDeepfake === false, [estimateDeepfake]),
    path: 'filters.deepfake',
    value: [],
    updateIf: useCallback((v) => !isEmpty(v), []),
  });
  const deepfakeTitle = (
    <div className={st.FoldTitle}>
      <h5>{t('handlers:policies.detect_policy.estimate_deepfake.estimate')}</h5>
      {!isDeepfakeAvailable && (
        <Tooltip
          className="Tooltip_ui"
          data-tooltip-content={t('handlers:policies.detect_policy.tooltip.deepfake license is required')}
          tooltipProps={{ effect: 'solid' }}
        >
          <CircleInfoIcon className={st.Tooltip_Icon_disabled} />
        </Tooltip>
      )}
    </div>
  );

  return (
    <Fold
      control={control}
      disabled={!isDeepfakeAvailable}
      isOpen={useWatch({ name: 'estimate_deepfake.estimate' })}
      name="estimate_deepfake.estimate"
      switch
      testId="estimate_deepfake"
      title={deepfakeTitle}
    >
      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        title={t('handlers:policies.detect_policy.estimate_deepfake.real_threshold')}
      >
        <Control.Input
          {...register('estimate_deepfake.real_threshold', {
            min: validate.gte(0),
            max: validate.lte(1),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        title={t('handlers:policies.detect_policy.estimate_deepfake.mode')}
      >
        <Control.Select
          control={control}
          name="estimate_deepfake.mode"
          options={DEEPFAKE_MODE_OPTIONS.raw}
        />
      </SettingsItemWrapper>
    </Fold>
  );
};

DeepfakeSubFormComponent.propTypes = {
  isDeepfakeAvailable: PropTypes.bool,
};
DeepfakeSubFormComponent.defaultProps = {
  isDeepfakeAvailable: false,
};

export const DeepfakeSubForm = connect((state) => ({
  isDeepfakeAvailable: selectIsAppFeatureAvailable(state, 'estimations.deepfake'),
}))(DeepfakeSubFormComponent);
