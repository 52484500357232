import React, { useContext, useRef } from 'react';

import { DownloadIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RoundButton } from '../../controls/button/RoundButton';
import { TableContext } from '../table-context';

const fileListToArray = (list) => {
  const array = [];
  for (let i = 0; i < list.length; i += 1) {
    array.push(list.item(i));
  }
  return array;
};

const ImportCell = ({
  inputProps,
  buttonProps,
}) => {
  const { actions } = useContext(TableContext);

  const onImport = actions.onImport?.handler;
  const hasImportPermission = actions.onImport?.hasPermission();
  const label = actions.onImport?.label;

  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const onFilesAddedHandler = async (event) => {
    if (!onImport) return;

    onImport(fileListToArray(event.target.files));
    fileInputRef.current.value = null;
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  if (!onImport) return null;

  return (
    <>
      <RoundButton
        data-testid="deleteCell"
        hasPermission={hasImportPermission}
        icon={<DownloadIcon />}
        kind="primary"
        onClick={openFileDialog}
        tabIndex="0"
        title={label || t('uikit:table.действия.импорт')}
        variant="dimmed"
        {...(buttonProps || {})}
      />
      <input
        onChange={onFilesAddedHandler}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        {...(inputProps || {})}
      />
    </>
  );
};

ImportCell.propTypes = {
  inputProps: PropTypes.object,
  buttonProps: PropTypes.object,
};

ImportCell.defaultProps = {
  inputProps: undefined,
  buttonProps: undefined,
};

export { ImportCell };
