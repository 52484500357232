import React, { useEffect, useMemo, useCallback, useRef } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { TASKS } from '@vlabs/api-bindings/src/luna-client/constants';
import { roles, permissions } from '@vlabs/shared/config';
import { useSteppedForm } from '@vlabs/shared/hooks';
import { selectAppService } from '@vlabs/shared/selectors/appSelectors';
import { Stepper, Page, Control, useModal, optionsProxy } from '@vlabs/uikit';
import cn from 'classnames';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { viewerCan, selectAccountEmail, selectAccountLogin, selectAccountRole } from '@vlabs/pages/auth/selectors';

import { showResourceIntensiveTaskPopup } from '../showResourceIntensiveTaskPopup';
import { StepCallbacksPolicy } from '../steps/StepCallbacksPolicy';
import { getDefaultValues } from './defaultValues';
import { StepEstimatorTask } from './StepEstimatorTask';
import st from './styles.module.sass';
import '../styles/shared.styles.sass';

// Разделение на два компонента необходимо чтобы избежать смешивания логики и
// чтобы избежать ошибок с активным шагом, который не сбрасывается при закрытии модального окна

const StepperModalComponent = ({ onSubmit, sourceTypes, email }) => {
  const { t } = useTranslation();
  const steps = [StepEstimatorTask, StepCallbacksPolicy];
  const defaultValues = getDefaultValues(sourceTypes, email);
  const form = useForm({ defaultValues, mode: 'onChange' });
  const prevForm = useRef(defaultValues);

  useEffect(() => {
    prevForm.current = defaultValues;
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.reset, prevForm]);

  const stepper = useSteppedForm({ form, steps, onSubmit });
  const context = { ...form, sourceTypes };

  const submitForm = useCallback((formValues) => { onSubmit(formValues, form); }, [onSubmit, form]);

  return (
    <FormProvider {...context}>
      <form onSubmit={form.handleSubmit(submitForm)}>
        <Page className={cn(st.Wrapper, 'TaskStepperModal__Page', 'EstimatorTaskForm')} title={t('tasks:lp.estimator task.title')}>
          <Stepper {...stepper} className={cn('TaskStepperModal__StepperContent')}>
            {steps.map((Step) => <Step key={Step.name} />)}
          </Stepper>
        </Page>
      </form>
    </FormProvider>
  );
};

const StepperModal = connect((state) => ({
  email: selectAccountLogin(state) ?? selectAccountEmail(state),
}))(StepperModalComponent);

export const EstimatorTaskWidgetComponent = ({ afterSubmit, role, lunaPlatform, can }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const sourceTypes = useMemo(() => {
    const options = [{
      label: t('lunaApi:estimator task.source.source_type.file'),
      value: 'file',
    }];
    if (role !== roles.ADMIN) return optionsProxy.create(options);

    options.push(
      TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.zip,
      TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.s3,
      TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.network_disk,
      TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.ftp,
    );
    if (lunaPlatform?.versionNum?.major >= 5
      && lunaPlatform?.versionNum?.minor >= 35) options.push(TASKS.ESTIMATOR.SOURCE.SOURCE_TYPE.samba);

    return optionsProxy.create(options);
  }, [role, lunaPlatform, t]);

  const submitForm = (values, form) => {
    const onConfirm = async () => {
      const formValues = cloneDeep(values);
      const sourceType = formValues.content.source.source_type.value;
      if (sourceType === 'file') {
        const { data } = await apiContainer.lunaClient.objects.create(formValues.content.source.file.reference[0]);
        formValues.content.source.zip = { reference: data.external_url };

        delete formValues.content.source.file;
        formValues.content.source.source_type = { value: 'zip' };
      }

      if ((sourceType === 'zip')
        && (!formValues.content.source.zip.reference.prefix && !formValues.content.source.zip.reference.postfix)) {
        const reference = formValues.content.source.zip.reference.url;
        formValues.content.source.zip.reference = reference;
      }

      try {
        await apiContainer.lunaClient.tasks.estimator(formValues);
        afterSubmit();
        modal.close();
        toast.info(t('tasks:подтверждение.пакетная обработка'));
      } catch (e) {
        if (e.error_code === 12031 && sourceType === sourceTypes.network_disk.value) {
          form.setError('content.source.network_disk.reference.path', { message: t('tasks:lp.estimator task.error.directory doesnt exist') });
          return;
        }
        throw e;
      }
    };
    showResourceIntensiveTaskPopup(onConfirm);
  };

  return (
    <>
      {modal.wrap(<StepperModal onSubmit={submitForm} sourceTypes={sourceTypes} />)}
      <Control.Button
        data-testid="packageProcessingButton"
        hasPermission={can(permissions.task.creation)}
        onClick={modal.open}
      >
        {t('tasks:пакетная обработка')}
      </Control.Button>
    </>
  );
};

EstimatorTaskWidgetComponent.propTypes = {
  can: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func,
  role: PropTypes.oneOf(Object.values(roles)),
  lunaPlatform: PropTypes.shape({
    versionNum: PropTypes.shape({
      major: PropTypes.number,
      minor: PropTypes.number,
    }),
  }),
};

EstimatorTaskWidgetComponent.defaultProps = {
  afterSubmit: undefined,
  role: undefined,
  lunaPlatform: undefined,
};

export const EstimatorTaskWidget = connect((state) => ({
  role: selectAccountRole(state),
  lunaPlatform: selectAppService(state, 'lunaPlatform'),
  can: viewerCan(state),
}))(EstimatorTaskWidgetComponent);
