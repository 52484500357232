import React, { useState } from 'react';

import { PersonIcon, ListIcon, FolderIcon, ArrowInCircleRightIcon } from '@vlabs/icons';
import { MatchTypeImage } from '@vlabs/shared/assets/images';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import { routes } from '@vlabs/shared/navigation/routes';
import { calcSimilarityPercent, calcBadgeType } from '@vlabs/shared/utils';
import { Badge, ButtonScrimGroup, ButtonScrimItem, Control } from '@vlabs/uikit';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import './TopMatchCell.sass';

const ExternalIdLink = ({ external_id }) => {
  return (
    <Control.Link
      to={generatePath(routes.externalId.read, { externalId: external_id })}
    >
      {external_id}
    </Control.Link>
  );
};

const ListLink = ({ listId, listLabel }) => {
  return (
    <Control.Link
      to={generatePath(routes.lists.read, { listId })}
    >
      {listLabel}
    </Control.Link>
  );
};

export const TopMatchFaceCard = ({
  topMatch,
  onTopMatchCardClick,
}) => {
  const { t } = useTranslation();
  const [isEmpty, setIsEmpty] = useState(false);

  return (
    <>
      {!isEmpty && topMatch?.isExist && topMatch?.avatar && (
        <div className="TopMatchSample__Wrapper">
          <img
            alt="topMatch face"
            className="TopMatchSample"
            key={`${topMatch.avatar}_topMatch`}
            onError={() => setIsEmpty(true)}
            src={topMatch.avatar}
          />

          {onTopMatchCardClick && (
            <ButtonScrimGroup className="TopMatchSample__GoTo">
              <ButtonScrimItem
                icon={<ArrowInCircleRightIcon />}
                onClick={onTopMatchCardClick}
              />
            </ButtonScrimGroup>
          )}
        </div>
      )}

      {(isEmpty || !topMatch?.isExist || !topMatch?.avatar) && (
        <div className="TopMatchSample__Wrapper">
          <EmptyImage
            className={cn(['TopMatchSample', 'TopMatchSample_nophoto'])}
            dataTip={topMatch?.isExist ? t('events:проверьте настройки сценария') : undefined}
            messageText={topMatch?.isExist ? t('events:top match cell.no image') : t('faces:лицо удалено')}
            onImageClick={topMatch?.isExist ? onTopMatchCardClick : undefined}
          />
        </div>
      )}
      <div className="InfoRow_wrapper">
        <Badge
          className="TopMatch__SimilarityBadge"
          kind={calcBadgeType(topMatch?.similarity)}
          value={calcSimilarityPercent(topMatch?.similarity)}
        />
        <InfoRow
          columnDirection
          data-testid="topMatchCell.externalId"
          icon={<img alt="match type icon" className="StaticIcon" src={MatchTypeImage} />}
          title={t('events:match type')}
          value={t('face')}
        />
        {topMatch?.external_id && (
          <InfoRow
            columnDirection
            data-testid="topMatchCell.externalId"
            icon={<PersonIcon className="StaticIcon" />}
            title={t('external id')}
            value={<ExternalIdLink {...topMatch} />}
          />
        )}
        {topMatch?.user_data && (
          <InfoRow
            columnDirection
            data-testid="topMatchCell.userData"
            icon={<FolderIcon className="StaticIcon" />}
            title={t('information')}
            value={topMatch.user_data}
          />
        )}
        {topMatch?.listId && (
          <InfoRow
            columnDirection
            data-testid="topMatchCell.lists"
            icon={<ListIcon className="StaticIcon" />}
            title={t('list')}
            value={<ListLink {...topMatch} />}
          />
        )}
      </div>
    </>
  );
};
