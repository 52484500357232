import React, { useState, useEffect, useCallback } from 'react';

import { CrossIcon } from '@vlabs/icons';
import { ButtonScrimGroup, ButtonScrimItem, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ImagePicker } from './ImagePicker';
import ListPicker from './ListPicker';
import st from './NewDetectionSelector.module.sass';
import { detectionType, underlayImageType } from './types';

function NewDetectionSelector({
  photo,
  detections,
  onSelect,
  onReset,
}) {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  const isSelected = useCallback((detectionId) => selected.some(
    (selectedDetection) => selectedDetection.id === detectionId,
  ), [selected]);

  const onSelectHandler = useCallback((detectionId) => {
    setSelected([detections.all.find((detection) => detection.id === detectionId)]);
  }, [detections]);

  useEffect(() => {
    if (!onSelect) return;
    onSelect(selected.map((f) => f.id));
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (detections && detections.all.length === 1) setSelected([detections.all[0]]);
  }, [detections]);

  return (
    <div className={st.Root} data-testid="detectionSelector">
      <div className={st.PickerContainer}>
        <div className={st.Col_1}>
          <ImagePicker
            detections={detections.all}
            isSelected={isSelected}
            onSelect={onSelectHandler}
            photo={photo}
          />
          {!!detections?.all?.length && (
            <ButtonScrimGroup position="top-right">
              <ButtonScrimItem
                icon={<CrossIcon />}
                kind="negative"
                onClick={onReset}
              />
            </ButtonScrimGroup>
          )}
        </div>

        <div className={st.Col_2}>
          {!!detections?.faces?.length && (
            <span className={st.Count} data-testid="detectionSelector.count">
              {t('detectionSelector:number of detected faces', { count: detections?.faces?.length })}
            </span>
          )}
          <ListPicker
            detections={detections?.faces}
            isSelected={isSelected}
            onSelect={onSelectHandler}
          />
          <Divider small />
          {!!detections?.bodies?.length && (
            <span className={st.Count} data-testid="detectionSelector.count">
              {t('detectionSelector:number of detected bodies', { count: detections?.bodies?.length })}
            </span>
          )}
          <ListPicker
            detections={detections.bodies}
            isSelected={isSelected}
            onSelect={onSelectHandler}
          />

        </div>
      </div>
    </div>
  );
}

NewDetectionSelector.propTypes = {
  photo: underlayImageType.isRequired,
  detections: PropTypes.arrayOf(detectionType),
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
};

NewDetectionSelector.defaultProps = {
  detections: [],
  onSelect: undefined,
  onReset: undefined,
};

export default NewDetectionSelector;
