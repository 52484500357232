import React, { useEffect, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CircleInfoIcon } from '@vlabs/icons';
import CheckTitle from '@vlabs/pages/checks/components/CheckTitle';
import { permissions } from '@vlabs/shared/config';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import { Control, Divider, Tabs, Tab } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { IsoErrorsTab } from '../components/IsoErrorsTab';
import { DefaultFaceFormFields } from './DefaultFaceFormFields';
import st from './IsoQualityCheckForm.module.sass';

const IsoQualityCheckFormComponent = ({
  isFaceQualityAvailable,
  can,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const { form, sampleId, detections, isoResult, setIsoResult, shouldCheckISO, setShouldCheckISO } = useFormContext();
  const file = useWatch({ name: 'file', control: form.control });

  const fetchIsoResults = async (file) => {
    const { images, raw } = await apiContainer.lunaClient.iso.check([file]);
    setIsoResult({ data: images[0], raw, status: images[0]?.estimations[0].face?.detection?.iso?.status });
  };

  useEffect(() => {
    if (!file || !shouldCheckISO || sampleId?.length === 0) {
      setIsoResult(undefined);
      setActiveTab(0);
      return;
    }

    async function run() {
      if (detections.length > 1) {
        const img = await apiContainer.lunaClient.samples.getImage('faces', ...sampleId);
        fetchIsoResults(img);
      } else {
        fetchIsoResults(file.raw);
      }
    }
    run();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, sampleId, shouldCheckISO, detections]);

  return (
    <Tabs activeTabIndex={activeTab} noHeaders>
      <Tab name="iso info">
        <div>
          <CircleInfoIcon className={st.CircleIcon} />
          <Trans i18nKey="faces:форма создания.подсказка" />
        </div>
        <Divider small />

        <DefaultFaceFormFields />
        <Divider small />

        {isFaceQualityAvailable && (
          <div className={st.Switch}>
            <div className={st.Switch__Label}>
              {t('externalID:createFace.form.check iso quality button label')}
            </div>
            <Control.Switch
              hasPermission={can(permissions.resources.iso)}
              onChange={setShouldCheckISO}
              value={shouldCheckISO}
            />
          </div>
        )}
        {isoResult?.data && (
          <>
            <CheckTitle
              className={st.ResultTitle}
              isSmallSize
              status={isoResult.status}
              title={t(`externalID:createFace.isoResultStatus.${Boolean(isoResult.status)}`)}
            />
            {!isoResult.status && (
              <>
                <Divider small />
                <Control.Button
                  data-testid="faceCreateForm.isoResultsButton"
                  fullWidth
                  kind="primary"
                  onClick={() => setActiveTab(1)}
                  variant="outlined"
                >
                  {t('externalID:createFace.form.button.view results')}
                </Control.Button>
              </>
            )}
          </>
        )}
      </Tab>
      <Tab name="iso error">
        <IsoErrorsTab results={isoResult?.data?.estimations[0]?.face} />
        <Divider small />

        <div className={st.TabButton}>
          <Control.Button
            onClick={() => setActiveTab(0)}
            variant="outlined"
          >
            {t('externalID:createFace.form.button.back')}
          </Control.Button>
        </div>
      </Tab>
    </Tabs>
  );
};

export const IsoQualityCheckForm = connect(
  (state) => ({
    isFaceQualityAvailable: selectIsAppFeatureAvailable(state, 'estimations.face_quality'),
    can: viewerCan(state),
  }),
)(IsoQualityCheckFormComponent);
