import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { Checkbox } from '../../controls/checkbox/Checkbox';
import { TableContext } from '../table-context';

import './SelectRowCell.sass';

const SelectRowCell = ({
  row,
}) => {
  const { actions } = useContext(TableContext);
  const { onChange: libOnChange, ...libProps } = row.getToggleRowSelectedProps();

  const onChange = actions.onSelectRow?.handler;
  const hasImportPermission = actions.onSelectRow?.hasPermission();

  if (hasImportPermission) return null;

  return (
    <div className="SelectRowCell">
      <Checkbox
        onChange={(e) => {
          libOnChange(e);
          if (onChange) onChange(row);
        }}
        {...libProps}
      />
    </div>
  );
};

SelectRowCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any),
});

SelectRowCell.defaultProps = ({
  row: undefined,
});

export { SelectRowCell };
