import React from 'react';

import { Sample } from '@vlabs/shared/components/sample/Sample';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { NewEventActions } from './NewEventActions';
import st from './samples.module.sass';

const EventBodySample = ({
  event,
  actions,
}) => {
  const { t } = useTranslation();

  // FIXME: разобраться и выкинуть, привести работу с фотками к одному компоненту
  if (!event || event?.isDeleted || !event?.uiBodyDetection?.uiSampleURL) {
    return (
      <div className={st.EventPage__Sample_body}>
        <div>{t(event?.isDeleted ? 'events:event deleted' : 'events:no body was found')}</div>
      </div>
    );
  }

  const defaultActions = (
    <NewEventActions
      fullFrameURL={event?.detectionWithUiFullFrame?.uiFullFrame}
      rect={event?.detectionWithUiFullFrame?.detection?.rect}
      sampleURL={event.uiBodyDetection?.uiSampleURL}
      // FIXME: это заглушка, в массиве uiBodyTrack всегда один элемент
      // мы никогда не показываем треки для тел, потому что просмотр трека подразумевает больше чем один элемент
      track={event?.uiBodyTrack}
    />
  );

  return (
    <Sample
      className={st.Sample_body}
      controls={actions || defaultActions}
      data-testid="detector.sample"
      image={event.uiBodyDetection?.uiSampleURL}
      key={event.uiBodyDetection?.uiSampleURL}
    />
  );
};

EventBodySample.propTypes = {
  event: PropTypes.shape({
    isDeleted: PropTypes.bool,
    body_detections: PropTypes.arrayOf(PropTypes.any),
    uiBodyDetection: PropTypes.objectOf(PropTypes.any),
    detectionWithUiFullFrame: PropTypes.shape({
      uiFullFrame: PropTypes.string,
      detection: PropTypes.shape({
        rect: PropTypes.objectOf(PropTypes.number).isRequired,
      }),
    }),
    uiBodyTrack: PropTypes.arrayOf(PropTypes.any),
  }),
  actions: PropTypes.node,
};
EventBodySample.defaultProps = {
  event: undefined,
  actions: undefined,
};

export { EventBodySample };
