import PropTypes from 'prop-types';

const check = (rules, role, actionName, data) => {
  const permissions = rules[role];

  if (!permissions) return false;

  if (permissions.static && permissions.static.includes(actionName)) {
    return true;
  }

  if (permissions.dynamic) {
    const condition = permissions.dynamic[actionName];
    if (!condition) return false;

    return condition(data);
  }

  return false;
};

const Can = ({
  rules,
  role,
  perform: actionName,
  data,
  yes,
  no,
}) => {
  if (check(rules, role, actionName, data)) {
    return yes ? yes() : null;
  }
  return no ? no() : null;
};

Can.propTypes = {
  rules: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.string.isRequired,
  perform: PropTypes.string.isRequired,
  data: PropTypes.any,
  yes: PropTypes.func,
  no: PropTypes.func,
};

Can.defaultProps = {
  data: undefined,
  yes: undefined,
  no: undefined,
};

const can = (...args) => check(...args);

export {
  Can,
  can,
};
