import React, { useState, useEffect, useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { PageWithSidebar } from '@vlabs/shared/components/page-with-sidebar';
import { permissions } from '@vlabs/shared/config';
import { FaceCard } from '@vlabs/shared/legacy-components/face-card/FaceCard';
import { routes } from '@vlabs/shared/navigation/routes';
import { CreateFaceWidget } from '@vlabs/shared/widgets/faces';
import { Control, Divider, openConfirmPopup, useModal } from '@vlabs/uikit';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useParams, useHistory } from 'react-router-dom';

import { viewerCanAllOf, viewerCan } from '@vlabs/pages/auth/selectors';

import st from './ExternalIdPage.module.sass';
import { FiltersForm } from './forms/FiltersForm';
import { VerifyForm } from './forms/VerifyForm';

function ExternalIdPageComponent({ can, canAllOf }) {
  const { t } = useTranslation();
  const { externalId } = useParams();
  const history = useHistory();
  const verifyModal = useModal();
  const [facesData, setFacesData] = useState();
  const [selectedFace, setSelectedFace] = useState(undefined);

  const fetchExternalIdFaces = useCallback(async (filters) => {
    const { data, meta } = await apiContainer.lunaClient.faces.getAll(
      { ...filters, external_ids: [externalId] },
      { withCount: true },
    );
    setFacesData({ data, meta: meta.count });
  }, [externalId]);

  useEffect(() => { fetchExternalIdFaces(); }, [fetchExternalIdFaces, externalId]);

  const verify = useCallback(async (faceId) => {
    const [sample] = await apiContainer.lunaClient.faces.attributes(faceId).getSamples();
    setSelectedFace({ faceId, photo: sample });

    verifyModal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFace = useCallback((faceId) => {
    const message = t('externalID:modal.delete face.confirmation.message');

    const onConfirm = async () => {
      await apiContainer.lunaClient.faces.delete(faceId);
      fetchExternalIdFaces();
    };

    openConfirmPopup({
      title: t('externalID:modal.delete face.confirmation.title'),
      message,
      type: 'delete',
      onConfirm,
      confirmButtonProps: {
        'data-testid': 'faceCard.confirmDeleteButton',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const identify = (id, referenceType) => {
    history.push({
      pathname: generatePath(routes.search),
      search: qs.stringify({
        references: [{
          id,
          type: 'face',
          referenceType,
        }],
      }),
      state: { id },
    });
  };

  return (
    <PageWithSidebar
      rigthSidebar={<FiltersForm onSubmit={fetchExternalIdFaces} />}
    >
      {verifyModal.wrap(<VerifyForm {...selectedFace} />)}
      <div className={st.Content}>
        <div className={st.Header}>
          <h4>
            {t('externalID:page.title', { facesCount: facesData?.meta || 0, externalId })}
          </h4>
          <div className={st.Header__Actions}>
            {/* FIXME: кнопки заменить на ссылку, т.к. мы переходим на другую страницу */}
            <Control.Button
              hasPermission={can(permissions.face.matching)}
              onClick={() => identify(externalId, 'face_external_id')}
              variant="outlined"
            >
              {t('externalID:action.identify person')}
            </Control.Button>
            <Divider small />
            <CreateFaceWidget afterSubmit={fetchExternalIdFaces} defaultValues={{ external_id: externalId }} />
          </div>
        </div>
        <div className={st.Body}>
          {facesData?.data?.map((face) => (
            <FaceCard
              face={face}
              key={face.face_id}
            >
              {/* FIXME: кнопки заменить на ссылку, т.к. мы переходим на другую страницу */}
              <Control.Button
                data-testid="faceCard.identifyButton"
                fullWidth
                hasPermission={can(permissions.face.matching)}
                onClick={() => identify(face.face_id, 'face')}
                variant="outlined"
              >
                {t('externalID:action.identify')}
              </Control.Button>
              {/* FIXME: кнопки заменить на ссылку, т.к. мы переходим на другую страницу */}
              <Control.Button
                data-testid="faceCard.verifyButton"
                fullWidth
                hasPermission={canAllOf(permissions.resources.sdk, permissions.attribute.view)}
                onClick={() => verify(face.face_id)}
                variant="outlined"
              >
                {t('externalID:action.verify')}
              </Control.Button>
              <Control.Button
                data-testid="faceCard.deleteButton"
                fullWidth
                hasPermission={can(permissions.face.deletion)}
                kind="negative"
                onClick={() => deleteFace(face.face_id)}
                variant="outlined"
              >
                {t('externalID:action.delete face')}
              </Control.Button>
            </FaceCard>
          ))}

          {!facesData?.data?.length && (
            <div className={st.EmptyPage} data-testid="externalId.emptyPage">
              {t('externalID:no data available')}
            </div>
          )}
        </div>
      </div>
    </PageWithSidebar>
  );
}

export const ExternalIdPage = connect(
  (state) => ({
    can: viewerCan(state),
    canAllOf: viewerCanAllOf(state),
  }))(ExternalIdPageComponent);
