import React, { useContext } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { addCellStyles } from './styles';
import { TableContext } from './table-context';
import './Table.sass';

const TableRow = ({
  row,
}) => {
  const {
    highlight,
    actions,
    rowProps,
  } = useContext(TableContext);

  const onClickRow = actions.onClickRow?.handler;
  const hasClickRowPermission = actions.onClickRow?.hasPermission();

  const onCellClick = ({ column }) => {
    if (column.selectable === false || column.type === 'action') return;
    if (typeof onClickRow === 'function' && hasClickRowPermission) {
      onClickRow(row.original);
    }
  };

  let highlightClassName;
  if (typeof highlight === 'boolean') highlightClassName = 'TableRow_highlighted';
  if (typeof highlight === 'function') {
    const result = highlight(row.original);
    if (result === true) highlightClassName = 'TableRow_highlighted';
    if (typeof result === 'string') highlightClassName = result;
  }

  const $getCellProps = (props, { cell: { column } }) => {
    return addCellStyles(props, column);
  };

  const $getRowProps = ($row) => {
    return typeof rowProps === 'function' ? rowProps($row) : rowProps;
  };

  return (
    <div
      className={cn({
        TableRow: true,
        TableRow_clickable: onClickRow,
        TableRow_selected: row.isSelected,
        [highlightClassName]: highlightClassName,
      })}
      data-testid="tableRow"
      {...row.getRowProps($getRowProps(row))}
    >
      <div className="TableRow__Overlay InteractionOverlay" />
      {row.cells.map((cell) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={cn({
            TableCell: true,
            TableCell_action: cell.column.type === 'action',
            TableCell_overflowScroll: cell.column.overflow === 'scroll',
            TableCell_overflowEllipsis: cell.column.overflow === 'ellipsis',
          })}
          data-testid="tableCell"
          onClick={() => onCellClick(cell)}
          {...cell.getCellProps($getCellProps)}
        >
          <div className="TableCellInner">
            {cell.render('Cell')}
          </div>
        </div>
      ))}
    </div>
  );
};

TableRow.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
};
TableRow.defaultProps = {
  row: undefined,
};

export { TableRow };
