import React from 'react';

import { useModal } from '@vlabs/uikit';

import { FullFrame } from './FullFrame';
import { Track } from './Track';

export function useTrackModal(props) {
  const modal = useModal();
  modal.wrap(Track);
  return {
    Component: modal.wrap(<Track {...props} />),
    isOpen: modal.isOpen,
    open: modal.open,
    close: modal.close,
  };
}
export function useFullFrameModal(props) {
  const modal = useModal();

  return {
    Component: modal.wrap(<FullFrame {...props} />),
    isOpen: modal.isOpen,
    open: modal.open,
    close: modal.close,
  };
}
