import React, { useContext } from 'react';

import { EditIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RoundButton } from '../../controls/button/RoundButton';
import { TableContext } from '../table-context';

const EditCell = ({
  row,
  buttonProps,
}) => {
  const { actions } = useContext(TableContext);
  const onEdit = actions.onEditRow?.handler;
  const hasEditPermission = actions.onEditRow?.hasPermission();
  const label = actions.onEditRow?.label;
  const { t } = useTranslation();

  if (!onEdit) return null;

  return (
    <RoundButton
      data-testid="editCell"
      hasPermission={hasEditPermission}
      icon={<EditIcon />}
      kind="primary"
      onClick={() => onEdit(row.original)}
      title={label || t('uikit:table.действия.редактирование элемента')}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

EditCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any),
  buttonProps: PropTypes.object,
});

EditCell.defaultProps = ({
  row: undefined,
  buttonProps: undefined,
});

export { EditCell };
