import React, { useState, useEffect, useCallback } from 'react';

import { CrossIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from '../../hooks/useWindowSize';
import { ButtonScrimGroup } from '../button-scrim-group/ButtonScrimGroup';
import { ButtonScrimItem } from '../button-scrim-group/ButtonScrimItem';

import './FaceSelector.sass';

const SelectableFace = ({
  id,
  width,
  height,
  left,
  top,
  selected,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <div
      aria-label={t('uikit:faceSelector.действия.выбор лица')}
      className={cn({
        FaceSelector__Face: true,
        FaceSelector__Face_selected: selected,
      })}
      onClick={() => onSelect(id)}
      onKeyPress={() => onSelect(id)}
      role="button"
      style={{
        width,
        height,
        left,
        top,
      }}
      tabIndex="0"
      title={t('uikit:faceSelector.действия.выбор лица')}
    />
  );
};

SelectableFace.propTypes = {
  id: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

SelectableFace.defaultProps = {
  selected: false,
};

const FaceSelector = ({
  photo,
  faces,
  onSelectFace,
  onReset,
  single,
}) => {
  const windowSize = useWindowSize();
  const [selectedFaces, setSelectedFaces] = useState([]);
  const [faceSelectors, setFaceSelectors] = useState([]);
  const [renderedImage, setRenderedImage] = useState(undefined);
  const { t } = useTranslation();

  const isSelected = (faceId) => selectedFaces.some((selectedFace) => selectedFace.id === faceId);

  const onSelectFaceHandler = useCallback((selectedFaceId) => {
    if (single) {
      setSelectedFaces([faces.find((face) => face.id === selectedFaceId)]);
      return;
    }
    if (isSelected(selectedFaceId)) {
      setSelectedFaces(selectedFaces.filter((face) => face.id !== selectedFaceId));
    } else {
      const currentFace = faces.find((face) => face.id === selectedFaceId);
      setSelectedFaces([...selectedFaces, currentFace]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faces, selectedFaces]);

  useEffect(() => {
    if (!onSelectFace) return;
    onSelectFace(selectedFaces.map((f) => f.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFaces]);

  const reset = () => {
    setSelectedFaces([]);
    if (onSelectFace) {
      onSelectFace([]);
    }
    if (onReset) {
      onReset();
    }
  };

  useEffect(() => {
    if (!renderedImage) return;
    if (!faces) return;

    const ratioW = renderedImage.width / renderedImage.naturalWidth;
    const ratioH = renderedImage.height / renderedImage.naturalHeight;
    const ratio = Math.min(ratioH, ratioW);
    if (faces) {
      setFaceSelectors(faces.map((f) => ({
        id: f.id,
        width: f.rect.width * ratio,
        height: f.rect.height * ratio,
        left: f.rect.x * ratio,
        top: f.rect.y * ratio,
      })));
    }
  }, [faces, renderedImage, windowSize]);

  useEffect(() => {
    if (faces && faces.length === 1) onSelectFaceHandler(faces[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faces]);

  const isError = photo && faces && !faces.length;

  return (
    <div className="FaceSelector">
      <div className="FaceSelector__Inner">
        {photo && !isError && (
          <ButtonScrimGroup className="FaceSelector__Controls">
            <ButtonScrimItem
              icon={<CrossIcon />}
              kind="negative"
              onClick={reset}
            />
          </ButtonScrimGroup>
        )}

        {isError && (
          <>
            <div className="FaceSelector__Scrim Scrim" />
            <div className="FaceSelector__ErrorMessage">
              {t('uikit:faceSelector.сообщение об отсутствии лиц на изображении')}
            </div>
          </>
        )}

        <img
          alt="Фотография для выбора лиц"
          className="FaceSelector__Photo"
          onChange={({ target }) => setRenderedImage(target)}
          onLoad={({ target }) => setRenderedImage(target)}
          src={photo}
        />

        {faceSelectors.map(({
          id, width, height, left, top,
        }) => (
          <SelectableFace
            height={height}
            id={id}
            key={id}
            left={left}
            onSelect={onSelectFaceHandler}
            selected={isSelected(id)}
            top={top}
            width={width}
          />
        ))}
      </div>
    </div>
  );
};

FaceSelector.propTypes = {
  photo: PropTypes.string.isRequired,
  faces: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    rect: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
  })),
  onSelectFace: PropTypes.func,
  onReset: PropTypes.func,
  single: PropTypes.bool,
};

FaceSelector.defaultProps = {
  faces: undefined,
  onReset: undefined,
  single: false,
  onSelectFace: undefined,
};

export { FaceSelector };
