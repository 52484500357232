// плюрализация для английского
//
// one ---- dog --- 1
// other -- dogs -- все кроме 1

export const en = {
  заголовки: {
    'страница не найдена': 'Page not found',
    'недостаточно прав для просмотра раздела': 'Insufficient permissions to view the section',
  },
  валидация: {
    required: 'This field is required',
    email: 'Invalid email format',
    'одинаковые пароли': 'Passwords do not match',
    'неподходящий формат файла': 'Invalid file format',
    число: {
      'больше или равно': 'The value must be greater than or equal to {{value}}',
      'меньше или равно': 'The value must be less than or equal to {{value}}',
      больше: 'The value must be greater than {{value}}',
      меньше: 'The value must be less than {{value}}',
      'только числа': 'Only integer and fractional numbers separated by "." are allowed to enter',
      целое: 'Only integers are allowed',
    },
    длина: {
      максимум_one: 'Maximum of {{count}} character',
      максимум_other: 'Maximum of {{count}} characters',
      минимум_one: 'Minimum of {{count}} character',
      минимум_other: 'Minimum of {{count}} characters',
      'максимум для каждого id': 'Maximum of {{count}} characters for each id',
    },
    'пароли не совпадают': 'Passwords do not match',
    uuid: 'For manual input, only UUID format is allowed',
    roi: 'Enter four comma-separated integers',
    'недопустимые значения': 'Ivalid values',
    regex: "Doesn't match pattern {{regex}}",
    uri: 'Invalid URI format',
  },
  формы: {
    поля: {
      логин: 'Login',
      email: 'Email',
      пароль: 'Password',
      'новый пароль': 'New password',
      'подтверждение нового пароля': 'Password confirmation',
    },
    действия: {
      вход: 'Sign in',
      выход: 'Sign out',
      'переход к восстановлению пароля': 'Recover password',
      'сохранение нового пароля': 'Save',
      'восстановление пароля': 'Recover',
    },
  },
  control: {
    file: {
      плейсхолдер: 'Choose a file',
      'сообщение для дропа файла': 'Drop file here',
    },
    select: {
      'создать опцию': 'Create "{{value}}"',
      плейсхолдер: 'Select...',
      'сообщение об отсутствии опций': 'No options to choose',
    },
    fileDropzone: {
      заголовок: 'Click or drag\'n\'drop the file',
      подзаголовок: 'Image or image package for indentification (jpg, png, zip)',
      'выбрано изображение': 'Selected image',
      'выбран архив': 'Selected archive',
      ошибки: {
        'неподходящий формат изображения': 'Unsuitable image format',
        'неподходящий формат архива': 'Unsuitable archive format',
        'неподходящий формат файла': 'Unsuitable file format',
        'неподходящий размер архива': 'Archive must be less than\u00A0{{size}}\u00A0Gb',
        'неподходящий размер изображения': 'Image must be less than\u00A0{{size}}\u00A0Mb',
      },
    },
    coordinatesInput: {
      modal: {
        заголовок: 'Area coordinates',
      },
      'подсказка с превью': 'Parameter setting can be done visually on the preview image',
      'подсказка без превью': 'The preview image must exist to complete the parameter setting',
    },
    btn: {
      'недостаточно прав': {
        title: 'Insufficient permissions',
      },
    },
  },
  table: {
    'сообщение пустой таблицы': 'No data',
    фильтры: {
      от: 'from',
      до: 'to',
      все: 'All',
    },
    действия: {
      'удаление выбранных элементов': 'Delete',
      'добавление нового элемента': 'Add',
      'удаление элемента': 'Delete',
      'редактирование элемента': 'Edit',
      экспорт: 'Export',
      импорт: 'Import',
    },
  },
  cam: {
    'сообщение об обсутствии изображения': 'No image available',
  },
  popup: {
    действия: {
      отмена: 'Cancel',
      подтверждение: 'Ok',
      удаление: 'Delete',
    },
  },
  copyButton: {
    успех: 'Copied',
  },
  faceCard: {
    поля: {
      пол: 'Sex',
      возраст: 'Age',
      'luna id': 'LUNA ID',
      'external id': 'External ID',
    },
    'сообщение об отсутствии фотографии': 'No photo',
  },
  faceSelector: {
    'сообщение об отсутствии лиц на изображении': 'There are no faces on this image',
    действия: {
      'выбор лица': 'Select',
    },
  },
  imagesPointsMatcher: {
    'подсказка по управлению': 'Ctrl + click (Cmd + click) to create. Double click to delete.',
  },
  listSelector: {
    заголовок: 'Lists',
    действия: {
      'выбор всех списков': 'Select all',
    },
  },
  polygonSelector: {
    'подсказка по завершению построения': 'Click on starting point to finish polygon creation',
    действия: {
      сохранение: 'Save',
      сброс: 'Reset',
      удаление: 'Delete',
    },
  },
  stepper: {
    шаг: 'Step {{count}}',
    действия: {
      'переход на шаг назад': 'Back',
      'переход на шаг вперед': 'Next',
    },
  },
  languageSelector: {
    lang: {
      ru: 'рус',
      en: 'eng',
    },
  },
  'images-points-canvas': {
    state: {
      init: 'Images are loading',
    },
  },
  roi: {
    'размер кадра': 'Frame size',
    действия: {
      сохранение: 'Save',
      'полный кадр': 'Full frame',
    },
  },
  errorToasts: {
    error: {
      title: 'Unknown error',
      message: {
        label: 'Message:',
      },
      stack: {
        label: 'Stack:',
      },
    },
    axios: {
      title: '{{service}} call error',
      http: {
        status: {
          label: 'HTTP Status:',
        },
        method: {
          label: 'HTTP Method:',
        },
        requestUrl: {
          label: 'Request URL:',
        },
      },
      response: {
        label: 'Response:',
      },
    },
    luna: {
      error_code: {
        label: 'Error code:',
      },
      desc: {
        label: 'Error description',
      },
      detail: {
        label: 'Error details',
      },
      link: {
        label: 'Link to documentation',
      },
    },
    copyButtonLabel: 'Copy error info',
  },
};

export const kitEnTranslations = {
  uikit: en,
};
