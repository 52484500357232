import { getAvatarsByFaceIds, getEventById, getExistedFaces, getExistedEvents } from '@vlabs/shared/requests';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';

import { selectHandlers } from '@vlabs/pages/handlers/selectors';

export async function processWSMessage(lastEvent, getState) {
  if (!lastEvent?.top_match) return lastEvent;

  const { data: handlerList } = selectHandlers(getState());
  const listOptions = selectListOptions(getState());

  const faceId = lastEvent.top_match?.face_id;
  const eventId = lastEvent.top_match?.event_id;

  const [faceAvatars, existedFaces, eventsById, existedEvents] = await Promise.all([
    getAvatarsByFaceIds(faceId),
    getExistedFaces(faceId),
    getEventById(eventId),
    getExistedEvents(eventId),
  ]);

  const handler = handlerList?.find(({ handlerId }) => handlerId === lastEvent.handler_id);
  const matchPolicy = handler?.matchPolicy;

  const listId = matchPolicy?.find(({ label }) => label === lastEvent.top_match?.label)?.listId;
  const currentList = listOptions.find(({ value }) => listId === value);

  return {
    ...lastEvent,
    top_match: {
      ...lastEvent.top_match,
      ...eventsById[lastEvent.top_match?.event_id],
      listLabel: currentList?.label || undefined,
      listId: listId || undefined,
      avatar: faceAvatars[lastEvent.top_match?.face_id] || eventsById[lastEvent.top_match?.event_id]?.avatar,
      isExist: existedFaces[faceId] || existedEvents[eventId],
      eventType: faceId ? 'face' : 'event',
    },
  };
}
