import { apiContainer } from '@vlabs/api-bindings';

export async function getListsByFaceIds(faceIds = []) {
  if (faceIds.length === 0) return {};

  const { data } = await apiContainer.lunaClient.faces.getAll({ face_ids: faceIds, targets: 'lists,face_id', page_size: faceIds.length });

  const faceObj = {};
  data.forEach((face) => {
    faceObj[face.face_id] = face.lists;
  });

  return faceObj;
}
