import { apiContainer } from '@vlabs/api-bindings';

export async function getAvatarsByFaceIds(faceIdsToProcess = []) {
  if ((faceIdsToProcess || []).length === 0) return {};
  const avatarUrls = {};

  const { data } = await apiContainer.lunaClient.faces
    .getAll({ face_ids: faceIdsToProcess, targets: 'avatar,face_id', page_size: faceIdsToProcess.length });

  data?.forEach((face) => {
    avatarUrls[face.face_id] = face?.uiAvatarURL;
  });

  return avatarUrls;
}
