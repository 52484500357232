import React from 'react';

import { ImageIcon, ZoomInIcon, MoreHorizontalIcon } from '@vlabs/icons';
import { ButtonScrimGroup, ButtonScrimItem } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTrackModal, useFullFrameModal } from './hooks';

const NewEventActions = ({
  sampleURL,
  fullFrameURL,
  track,
  rect,
  extraActions,
}) => {
  const { t } = useTranslation();
  const trackModal = useTrackModal({ detections: track });
  const imageOriginModal = useFullFrameModal({ fullFrameURL, rect });

  return (
    <>
      {imageOriginModal.Component}
      {trackModal.Component}

      <ButtonScrimGroup>
        <ButtonScrimItem
          as={sampleURL ? 'a' : 'div'}
          disabled={!sampleURL}
          href={sampleURL}
          icon={<ZoomInIcon />}
        />

        <ButtonScrimItem
          data-testid="eventActions.viewTrackButton"
          disabled={!track || track.length <= 1}
          icon={<MoreHorizontalIcon />}
          onClick={trackModal.open}
          title={t('просмотр трека')}
        />

        <ButtonScrimItem
          data-testid="eventActions.fullFrameButton"
          disabled={!fullFrameURL}
          icon={<ImageIcon />}
          onClick={imageOriginModal.open}
          title={t('полный кадр')}
        />
        {extraActions}
      </ButtonScrimGroup>
    </>
  );
};

NewEventActions.propTypes = {
  extraActions: PropTypes.node,
  sampleURL: PropTypes.string,
  track: PropTypes.arrayOf(PropTypes.shape({
    uiSampleURL: PropTypes.string,
  })),
  fullFrameURL: PropTypes.string,
  rect: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};
NewEventActions.defaultProps = {
  extraActions: undefined,
  sampleURL: undefined,
  track: undefined,
  fullFrameURL: undefined,
  rect: undefined,
};

export { NewEventActions };
