import React from 'react';

import { LinkBrokenIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';

export const DeleteAndDetachCell = ({ onClick, row, buttonProps }) => (
  <Control.RoundButton
    data-testid="linkBrokenCell"
    icon={<LinkBrokenIcon />}
    kind="negative"
    onClick={() => onClick(row.original)}
    title={i18n.t('lists:таблица.кнопки.удалить список и открепить лица')}
    variant="dimmed"
    {...(buttonProps || {})}
  />
);

DeleteAndDetachCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func.isRequired,
});

DeleteAndDetachCell.defaultProps = ({
  buttonProps: undefined,
});
