import React from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { permissions } from '@vlabs/shared/config';
import { Control, useModal, openConfirmPopup } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { GarbageCollectionStepperForm } from './GarbageCollectionStepperForm';

const GarbageCollectionWidgetComponent = ({ afterSubmit, filters, can }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const submitForm = (values) => {
    openConfirmPopup({
      title: t('tasks:GCTask.подтверждение.заголовок'),
      message: t('tasks:GCTask.подтверждение.сообщение'),
      type: 'delete',
      onConfirm: async () => {
        await apiContainer.lunaClient.tasks.createGCTask(values);
        toast.info(t('tasks:api.GCTask.создание.успех'));
        modal.close();
        if (afterSubmit) afterSubmit();
      },
      confirmLabel: t('кнопка.ок'),
    });
  };

  return (
    <>
      {modal.wrap(<GarbageCollectionStepperForm filters={filters} onSubmit={submitForm} />)}
      <Control.Button
        data-testid="garbageCollectionButton"
        hasPermission={can(permissions.task.creation)}
        onClick={modal.open}
      >
        {t('tasks:GCTask.кнопка.удаление лиц из списка')}
      </Control.Button>
    </>
  );
};

GarbageCollectionWidgetComponent.propTypes = {
  can: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func,
  filters: PropTypes.shape({
    listId: PropTypes.string,
  }),
};

GarbageCollectionWidgetComponent.defaultProps = {
  afterSubmit: undefined,
  filters: {},
};

export const GarbageCollectionWidget = connect((state) => ({
  can: viewerCan(state),
}))(GarbageCollectionWidgetComponent);
