import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import { Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './LocationInfo.module.sass';

export function LocationInfo({ ...original }) {
  const { t } = useTranslation();
  const { area, city, district, geo_position, house_number, street } = original?.location ?? {};

  let locationInfo = (
    <div className={st.Flex}>
      <CircleInfoIcon className="StaticIcon" />
      <div>{t('events:данные о локации отсутствуют')}</div>
    </div>
  );

  if (original?.location) {
    locationInfo = (
      <>
        <InfoRow title={t('геопозиция.город')} value={city} />
        <InfoRow title={t('геопозиция.область')} value={area} />
        <InfoRow title={t('геопозиция.район')} value={district} />
        <InfoRow title={t('геопозиция.улица')} value={street} />
        <InfoRow title={t('геопозиция.номер дома')} value={house_number} />
        <InfoRow title={t('геопозиция.широта')} value={geo_position?.latitude} />
        <InfoRow title={t('геопозиция.долгота')} value={geo_position?.longitude} />
      </>
    );
  }

  return (
    <>
      <h6>{t('location')}</h6>
      <Divider small />
      {locationInfo}
    </>
  );
}

LocationInfo.propTypes = {
  original: PropTypes.objectOf(PropTypes.any),
};

LocationInfo.defaultProps = {
  original: undefined,
};
