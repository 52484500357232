/* eslint-disable import/order */
// redux ломается если что-то импортировано не так как он хочет
import React from 'react';
import 'app/translations/i18n';

import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { store } from 'app/store';
import { routes } from 'app/navigation/routes';
import { setRoutes } from '@vlabs/shared/navigation/routes';
import App from 'app/App';
import { initApp } from './app/store/appSlice';
import * as serviceWorker from './serviceWorker';
import { registerErrorHandlers } from 'utils/errors';
import 'api/index';
import 'react-toastify/dist/ReactToastify.css';
import 'app/styles/index.sass';
import 'app/styles/toasts.sass';

setRoutes(routes);
registerErrorHandlers();

store.dispatch(initApp);

const history = createBrowserHistory();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router history={history}>
      <Provider store={store}>
        <App />
        <ToastContainer icon={false} position={toast.POSITION.TOP_LEFT} />
      </Provider>
    </Router>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// expose store when run in Cypress
// FIXME: use only in test environment
if (window.Cypress) {
  window.cprs = {
    store,
    history,
  };
}
