import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { getWsEventsQS } from '@vlabs/api-bindings/src/luna-client/events/mappers';
import { roles } from '@vlabs/shared/config';
import { fetchEvents as fetchEventsRequest, processWSMessage } from '@vlabs/shared/requests';
import UIfx from 'uifx';

import { selectAccountRole } from '@vlabs/pages/auth/selectors';
import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

const notify = new UIfx(`/static/media/attention.mp3?v=${Math.random()}`);

const store = createSlice({
  name: 'latestEvents',
  initialState: {
    data: [],
    filters: {},
    pageSize: 30,
    pageSizeOptions: [30],
    allIds: [],
    isSoundOn: false,
    soundThreshold: 50,
    isTopMatchHiding: false,
  },
  reducers: {
    setEvents(state, { payload: { events } }) {
      state.data = events;
      state.allIds = events?.map(({ event_id }) => event_id);
    },
    setParams(state, { payload: { filters, isSoundOn, soundThreshold } = {} }) {
      state.filters = filters;
      if (isSoundOn !== undefined) state.isSoundOn = isSoundOn;
      if (soundThreshold !== undefined) state.soundThreshold = soundThreshold;
    },
    setAsyncData: {
      reducer(state, { payload: event }) {
        if (!state.allIds.includes(event.event_id)) {
          state.data = [event, ...state.data].slice(0, state.pageSize);
        } else return;
        if (state.isSoundOn && state.soundThreshold
          && event?.top_match?.similarity >= Number((state.soundThreshold / 100).toFixed(4))) {
          notify.play();
        }
      },
      prepare: (rawData) => ({ payload: typeof rawData === 'string' ? JSON.parse(rawData) : rawData }),
    },
    setIsTopMatchHiding(state) {
      state.isTopMatchHiding = !state.isTopMatchHiding;
    },
  },
});

export default store.reducer;

export const { setAsyncData, setEvents, setParams, setIsTopMatchHiding } = store.actions;

export const disconnectAsyncEvents = async () => {
  await apiContainer.lunaClient.ws.disconnect();
};

export const connectAsyncEvents = (params = {}) => async (dispatch, getState) => {
  await disconnectAsyncEvents();
  const isAccountUser = selectAccountRole(getState()) === roles.USER;
  const accountSources = selectSourceOptionsByAccount(getState())
    .reduce((acc, { options }) => [...acc, ...options], [])
    .map(({ value }) => value);

  if (isAccountUser && accountSources.length === 0) {
    return;
  }
  const localParams = { ...params };
  if (isAccountUser && !localParams.sources) {
    localParams.sources = accountSources;
  }

  apiContainer.lunaClient.ws.connect(localParams, null, { startClosed: false });
  apiContainer.lunaClient.ws.onmessage = async (event) => {
    dispatch(setAsyncData(await processWSMessage(event, getState)));
  };
};

export const fetchLatestEvents = async (dispatch, getState) => {
  const isAccountUser = selectAccountRole(getState()) === roles.USER;
  const accountSources = selectSourceOptionsByAccount(getState())
    .reduce((acc, { options }) => [...acc, ...options], [])
    .map(({ value }) => value);

  const { latestEvents: { pageSize, filters } } = getState();

  if (isAccountUser && accountSources.length === 0) {
    dispatch(setEvents({ events: [] }));
    return;
  }

  const localFilters = { ...filters };

  if (isAccountUser && !localFilters.sources?.length) {
    localFilters.sources = accountSources;
  }

  try {
    const events = await fetchEventsRequest(pageSize, 0, localFilters);
    dispatch(setEvents({ events }));
  } catch (error) {
    dispatch(setEvents({ events: [] }));
    throw error;
  }
};

export const resetFilters = (dispatch) => {
  dispatch(setParams({ filters: {} }));
};

export const updateFilters = (params) => async (dispatch) => {
  const { filters } = params;
  const asyncFilters = getWsEventsQS(filters);

  dispatch(setParams(params));
  dispatch(fetchLatestEvents);
  dispatch(connectAsyncEvents(asyncFilters));
};
