import React from 'react';

import { EYE_STATUS_OPTIONS, MOUTH_STATUS_OPTIONS } from '@vlabs/api-bindings/src/constants';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import { useTranslation } from 'react-i18next';

import st from './tabs.module.sass';

const AdditionalPropertiesTab = ({
  quality,
  attributes,
}) => {
  const { t } = useTranslation();
  let mouthComponent = null;
  let eyesComponent = null;

  if (attributes?.mouth_attributes && Object.keys(attributes.mouth_attributes).length !== 0) {
    const { score, ...attribute } = attributes.mouth_attributes;

    const maxAttribute = Object.entries(attribute).sort((attr1, attr2) => attr2[1] - attr1[1])[0];
    mouthComponent = <div>{`${MOUTH_STATUS_OPTIONS[maxAttribute[0]].label} (${maxAttribute[1]})`}</div>;
  }

  if (attributes?.eyes_attributes) {
    eyesComponent = (
      <div className={st.AttributesWrapper}>
        <div className={st.AttributesWrapper_margin}>
          {`${t('левый')} (${EYE_STATUS_OPTIONS[attributes.eyes_attributes.left_eye?.state]?.label})`}
        </div>
        <div>{`${t('правый')} (${EYE_STATUS_OPTIONS[attributes.eyes_attributes.right_eye?.state]?.label})`}</div>
      </div>
    );
  }

  return (
    <div className={st.AdditionalPropertiesTab}>
      <InfoRow title={t('наклон головы (roll)')} value={attributes?.head_pose?.roll} />
      <InfoRow title={t('наклон головы (pitch)')} value={attributes?.head_pose?.pitch} />
      <InfoRow title={t('наклон головы (yaw)')} value={attributes?.head_pose?.yaw} />
      <InfoRow title={t('направление взгляда (pitch)')} value={attributes?.gaze?.pitch} />
      <InfoRow title={t('направление взгляда (yaw)')} value={attributes?.gaze?.yaw} />
      <InfoRow title={t('засвеченность')} value={quality?.light} />
      <InfoRow title={t('затемненность')} value={quality?.dark} />
      <InfoRow title={t('размытость')} value={quality?.blurriness} />
      <InfoRow title={t('наличие бликов')} value={quality?.specularity} />
      <InfoRow title={t('равномерность освещения')} value={quality?.illumination} />
      <InfoRow title={t('статус рта')} value={mouthComponent} />
      <InfoRow title={t('статус глаз')} value={eyesComponent} />
    </div>
  );
};

export { AdditionalPropertiesTab };
