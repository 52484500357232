import React from 'react';

import { LinkBrokenIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import i18n from 'i18next';
import PropTypes from 'prop-types';

export const DetachCell = ({
  onClick,
  buttonProps,
  row,
}) => {
  return (
    <Control.RoundButton
      data-testid="detachCell"
      icon={<LinkBrokenIcon />}
      kind="primary"
      onClick={() => onClick(row.original)}
      title={i18n.t('faces:кнопки.открепление')}
      variant="dimmed"
      {...(buttonProps || {})}
    />
  );
};

DetachCell.propTypes = ({
  row: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
  buttonProps: PropTypes.object,
});

DetachCell.defaultProps = ({
  row: undefined,
  onClick: undefined,
  buttonProps: undefined,
});
