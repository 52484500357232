import React from 'react';

import { Sample } from '@vlabs/shared/components/sample/Sample';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './samples.module.sass';

function EventFaceSample({ event, actions }) {
  const { t } = useTranslation();

  // FIXME: разобраться и выкинуть, привести работу с фотками к одному компоненту
  if (!event || event?.isDeleted || !event.uiFaceDetection?.uiSampleURL) {
    return (
      <div className={st.Sample_face}>
        <span>{t('events:no face was found')}</span>
      </div>
    );
  }

  return (
    <Sample
      controls={actions}
      key={event?.uiFaceDetection?.uiSampleURL}
      image={event?.uiFaceDetection?.uiSampleURL}
      // FIXME: разобраться и выкинуть, сейчас смысла в этой строчке нет, если есть isDeleted то мы выполним код выше..
      isDeleted={event?.isDeleted}
      className={st.Sample_face}
    />
  );
}

EventFaceSample.propTypes = {
  event: PropTypes.shape({
    isDeleted: PropTypes.bool,
    face_detections: PropTypes.arrayOf(PropTypes.any),
    uiFaceDetection: PropTypes.objectOf(PropTypes.any),
    uiFaceTrack: PropTypes.arrayOf(PropTypes.number),
  }),
  actions: PropTypes.node,
};

EventFaceSample.defaultProps = {
  event: undefined,
  actions: undefined,
};

export { EventFaceSample };
