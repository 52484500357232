import React, { useEffect, useState } from 'react';

import { useWindowSize } from '@vlabs/uikit';
import PropTypes from 'prop-types';

import st from './ImagePicker.module.sass';
import { ImageSelector } from './ImageSelector';
import { underlayImageType, detectionType } from './types';

const ImagePicker = ({
  photo,
  detections,
  isSelected,
  onSelect,
}) => {
  const windowSize = useWindowSize();
  const [renderedImage, setRenderedImage] = useState(undefined);
  const [selectors, setSelectors] = useState([]);

  useEffect(() => {
    if (!renderedImage) return;
    if (!detections) return;

    const ratioW = renderedImage.width / renderedImage.naturalWidth;
    const ratioH = renderedImage.height / renderedImage.naturalHeight;
    const ratio = Math.min(ratioH, ratioW);
    if (detections) {
      setSelectors(detections.map(({ id, rect, type }) => ({
        id,
        type,
        // FIXME: проверить rect, добавить propTypes
        rect: {
          // eslint-disable-next-line no-unsafe-optional-chaining
          width: rect?.width * ratio,
          // eslint-disable-next-line no-unsafe-optional-chaining
          height: rect?.height * ratio,
          // eslint-disable-next-line no-unsafe-optional-chaining
          left: rect?.x * ratio,
          // eslint-disable-next-line no-unsafe-optional-chaining
          top: rect?.y * ratio,
        },
      })));
    }
  }, [detections, renderedImage, windowSize]);
  return (
    <div className={st.Root}>
      <div className={st.Container}>
        <img
          alt="Фотография для выбора детекции"
          className={st.Image}
          data-testid="imagePicker.image"
          onChange={({ target }) => setRenderedImage(target)}
          onLoad={({ target }) => setRenderedImage(target)}
          src={photo}
        />
        {selectors.map(({ id, rect, type }) => (
          <ImageSelector
            id={id}
            key={id}
            onSelect={onSelect}
            rect={rect}
            selected={isSelected(id)}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

ImagePicker.propTypes = {
  photo: underlayImageType.isRequired,
  detections: PropTypes.arrayOf(detectionType),
  isSelected: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

ImagePicker.defaultProps = {
  detections: [],
};

export { ImagePicker };
