import { apiContainer } from '@vlabs/api-bindings';
import { getEventsQS } from '@vlabs/api-bindings/src/luna-client/events/mappers';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';

import { selectHandlers } from '@vlabs/pages/handlers/selectors';
import { getState } from '@vlabs/shared/redux/store';

import { getAvatarsByFaceIds } from './getAvatarsByFaceIds';
import { getEventById } from './getEventById';
import { getExistedEvents } from './getExistedEvents';
import { getExistedFaces } from './getExistedFaces';

export async function fetchEvents(pageSize, pageIndex, filters) {
  const { data: handlerList } = selectHandlers(getState());
  const listOptions = selectListOptions(getState());

  const events = await apiContainer.lunaClient.events
    .getAll(getEventsQS({ ...filters, page: pageIndex + 1, page_size: pageSize }));

  const matchResultFaceIds = events?.filter(({ top_match }) => top_match)
    .filter(({ top_match: { face_id } }) => face_id)
    .map(({ top_match: { face_id } }) => face_id);

  const matchResultEventsIds = events?.filter(({ top_match }) => top_match)
    .filter(({ top_match: { event_id } }) => event_id)
    .map(({ top_match: { event_id } }) => event_id);

  const [faceAvatars, existedFaces, eventsById, existedEvents] = await Promise.all([
    getAvatarsByFaceIds(matchResultFaceIds),
    getExistedFaces(matchResultFaceIds),
    getEventById(matchResultEventsIds),
    getExistedEvents(matchResultEventsIds),
  ]);

  const result = events?.map((event) => {
    if (!event?.top_match) return event;

    const handler = handlerList?.find(({ handlerId }) => handlerId === event.handler_id);
    const matchPolicy = handler?.matchPolicy;

    const listId = matchPolicy?.find(({ label }) => label === event.top_match?.label)?.listId;
    const currentList = listOptions.find(({ value }) => listId === value);

    return {
      ...event,
      top_match: {
        ...event.top_match,
        ...eventsById[event.top_match?.event_id],
        listLabel: currentList?.label || undefined,
        listId: listId || undefined,
        avatar: faceAvatars[event.top_match?.face_id],
        isExist: existedFaces[event.top_match?.face_id]
          || existedEvents[event.top_match?.event_id],
        eventType: event.top_match?.face_id ? 'face' : 'event',
      },
    };
  });

  return result;
}
