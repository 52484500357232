import React from 'react';

import { CircleCrossIcon, PersonPlusIcon } from '@vlabs/icons';
import { SearchLink } from '@vlabs/pages/events/event-page/components';
import { NewEventActions } from '@vlabs/pages/events/event-page/components/NewEventActions';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { AdditionalPropertiesTab } from '@vlabs/shared/legacy-components/tabs/AdditionalPropertiesTab';
import { AttributesTab } from '@vlabs/shared/legacy-components/tabs/AttributesTab';
import { BodyAttributesTab } from '@vlabs/shared/legacy-components/tabs/BodyAttributesTab';
import { Tab, Tabs, Panel, Divider, useModal, ButtonScrimItem } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CreateFaceFromDetectionForm } from './CreateFaceFromDetectionForm';
import st from './DetectionCard.module.sass';
import { EventBodySample } from './EventBodySample';
import { EventFaceSample } from './EventFaceSample';

export const DetectionCard = ({ event }) => {
  const { t } = useTranslation();
  const createFaceModal = useModal();

  // FIXME: разобраться что за NewEventActions и выделить в один компонент с EventActions. или назвать нормально
  const actions = (
    <NewEventActions
      extraActions={(
        <ButtonScrimItem
          data-testid="eventActions.createFaceButton"
          icon={<PersonPlusIcon />}
          onClick={createFaceModal.open}
          title={t('events:action.create face')}
        />
      )}
      fullFrameURL={event?.detectionWithUiFullFrame?.uiFullFrame}
      rect={event?.detectionWithUiFullFrame?.detection?.rect}
      sampleURL={event?.uiFaceDetection?.uiSampleURL}
      track={event?.uiBodyTrack}
    />
  );

  return (
    <Panel className={st.Root}>
      {createFaceModal.wrap(<CreateFaceFromDetectionForm
        afterSubmit={createFaceModal.close}
        detection={event.uiFaceDetection}
      />)}

      <div className={st.TitleBlock}>
        <h6>{t('events:event card.title')}</h6>
        <SearchLink event={event} />
      </div>

      <Divider small />

      <div className={st.Content}>
        <div>
          {event.face_detections && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <EventFaceSample actions={actions} event={event} />
            </div>
          )}
          {event.body_detections && <EventBodySample event={event} />}

          {!event.face_detections && !event.body_detections && (
            <EmptyImage
              className={st.Empty_Image}
              icon={<CircleCrossIcon />}
              messageText={t('объект не обнаружен')}
            />
          )}
        </div>

        <div>
          <Tabs testId="detectionInfoTabs">
            <Tab header={t('атрибуты')} headerClassName={st.TabHeader} name="detectionAttributes">
              {event.face_detections && (
                <>
                  <AttributesTab {...event} />
                  <Divider small />
                </>
              )}
              {event.body_detections && <BodyAttributesTab event={event} />}
            </Tab>

            <Tab header={t('дополнительные свойства')} headerClassName={st.TabHeader} name="detectionAdditionalProperties">
              <AdditionalPropertiesTab {...event?.detection} />
            </Tab>
          </Tabs>
        </div>

      </div>
    </Panel>
  );
};

DetectionCard.propTypes = {
  event: PropTypes.objectOf(PropTypes.any),
};

DetectionCard.defaultProps = {
  event: {},
};
