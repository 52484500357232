import React, { useState } from 'react';

import { ROLE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import {
  ClockIcon,
  ListIcon,
  VideoIcon,
  PersonsIcon,
  SlidersIcon,
  LogOutIcon,
  HomeIcon,
  CrosshairIcon,
  FileIcon,
  NotificationIcon,
  ServerIcon,
  ThermometerIcon,
  PersonCheckIcon,
  MapIcon,
  FileTextIcon,
  DollarSignIcon,
  MoonIcon,
  SunIcon,
  GridIcon,
  BookIcon,
  RecognitionIcon,
} from '@vlabs/icons';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import {
  selectIsAppFeatureAvailable,
  selectAppServiceState,
} from '@vlabs/shared/selectors/appSelectors';
import {
  Layout,
  AccountMenuButton,
  AccountMenuLink,
  LanguageSelector,
  Control,
  Account,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  selectIsAuthenticated,
  selectAccountRole,
  selectAccountModel,
  viewerCan,
} from '@vlabs/pages/auth/selectors';
import { logout } from '@vlabs/pages/auth/store';

const Navigation = ({
  account,
  role,
  onLogout,
  isAuthenticated,
  lunaPlatformIsAvailable,
  thermoIsAvailable,
  lunaStreamsIsAvailable,
  clementineAuthIsAvailable,
  clementineDepartmentsIsAvailable,
  notifierIsAvailable,
  vlAccessIsAvailable,
  vaReporterIsAvailable,
  isFaceQualityAvailable,
  can,
}) => {
  const { t } = useTranslation();

  const [isLightTheme, setIsLightTheme] = useState(window.localStorage.getItem('vl-theme') !== 'dark');

  if (!isAuthenticated) return null;

  const toggleTheme = () => {
    const newTheme = isLightTheme ? 'dark' : 'light';
    window.localStorage.setItem('vl-theme', newTheme);

    const style = document.getElementById('theme-changing');
    style.sheet.insertRule('* { transition: unset !important; }');
    document.documentElement.setAttribute('data-theme', newTheme);
    setTimeout(() => style.sheet.deleteRule(0), 0);
    setIsLightTheme(!isLightTheme);
  };

  return (
    <Layout.Navbar>
      {lunaPlatformIsAvailable && (
        <Layout.NavbarLink exact icon={<ClockIcon />} to={routes.events.latest}>
          {t('последние события')}
        </Layout.NavbarLink>
      )}
      {lunaPlatformIsAvailable && (
        <Layout.NavbarLink icon={<ListIcon />} to={routes.events.archive}>
          {t('архив событий')}
        </Layout.NavbarLink>
      )}
      {lunaPlatformIsAvailable && (
        <Layout.NavbarLink icon={<CrosshairIcon />} to={routes.search}>
          {t('поиск')}
        </Layout.NavbarLink>
      )}
      {lunaPlatformIsAvailable && (
        <Layout.NavbarLink icon={<PersonsIcon />} to={routes.faces.all}>
          {t('лица')}
        </Layout.NavbarLink>
      )}
      {lunaPlatformIsAvailable && (
        <Layout.NavbarLink icon={<ListIcon />} to={routes.lists.all}>
          {t('списки')}
        </Layout.NavbarLink>
      )}
      {vaReporterIsAvailable && (
        <>
          <Layout.NavbarLink icon={<MapIcon />} to={routes.vaReporter.plans.listAll}>
            {t('планы')}
          </Layout.NavbarLink>
          <Layout.NavbarLink icon={<FileTextIcon />} to={routes.vaReporter.reports}>
            {t('отчеты')}
          </Layout.NavbarLink>
        </>
      )}

      <LanguageSelector />

      <Control.Button
        icon={isLightTheme ? <MoonIcon /> : <SunIcon />}
        kind="neutral"
        onClick={toggleTheme}
        variant="flat"
      />

      {/* eslint-disable-next-line jsx-a11y/aria-role */}
      <Account name={account.name} role={ROLE_OPTIONS[role]?.label}>
        {lunaPlatformIsAvailable && can(permissions.handler.view) && (
          <AccountMenuLink
            icon={<SlidersIcon />}
            to={routes.handlers.all}
          >
            {t('сценарии')}
          </AccountMenuLink>
        )}

        {lunaPlatformIsAvailable && (
          <AccountMenuLink
            icon={<PersonCheckIcon />}
            to={routes.verifiers.all}
          >
            {t('верификация')}
          </AccountMenuLink>
        )}

        {(lunaStreamsIsAvailable || vlAccessIsAvailable) && (
          <AccountMenuLink
            icon={<VideoIcon />}
            to={routes.sources.main}
          >
            {t('источники событий')}
          </AccountMenuLink>
        )}

        {lunaPlatformIsAvailable && (
          <AccountMenuLink
            icon={<FileIcon />}
            to={routes.tasks.all}
          >
            {t('задачи')}
          </AccountMenuLink>
        )}

        {lunaPlatformIsAvailable && isFaceQualityAvailable && (
          <AccountMenuLink
            icon={<RecognitionIcon />}
            to={routes.checks.read}
          >
            {t('checks:page.title.checks')}
          </AccountMenuLink>
        )}

        {clementineAuthIsAvailable && (
          <AccountMenuLink
            icon={<BookIcon />}
            to={routes.searchByDocuments}
          >
            {t('поиск по документам')}
          </AccountMenuLink>
        )}

        {clementineAuthIsAvailable && can(permissions.account.view) && (
          <AccountMenuLink
            icon={<PersonsIcon />}
            to={routes.accounts.all}
          >
            {t('пользователи')}
          </AccountMenuLink>
        )}

        {clementineDepartmentsIsAvailable && can(permissions.department.view) && (
          <AccountMenuLink
            icon={<HomeIcon />}
            to={routes.departments.all}
          >
            {t('отделения')}
          </AccountMenuLink>
        )}

        {notifierIsAvailable && can(permissions.notification.view) && (
          <AccountMenuLink
            icon={<NotificationIcon />}
            to={routes.notifications.all}
          >
            {t('уведомления')}
          </AccountMenuLink>
        )}

        {thermoIsAvailable && can(permissions.thermo.read) && (
          <AccountMenuLink
            icon={<ThermometerIcon />}
            to={routes.thermo.read}
          >
            {t('Термо')}
          </AccountMenuLink>
        )}

        {can(permissions.appInfo.view) && (
          <AccountMenuLink
            icon={<ServerIcon />}
            to={routes.app.info}
          >
            {t('мониторинг')}
          </AccountMenuLink>
        )}

        {can(permissions.appInfo.view) && (
          <AccountMenuLink
            icon={<DollarSignIcon />}
            to={routes.app.features}
          >
            {t('features')}
          </AccountMenuLink>
        )}

        {can(permissions.appInfo.view) && (
          <AccountMenuLink
            icon={<GridIcon />}
            to={routes.app.plugins}
          >
            {t('plugins:title')}
          </AccountMenuLink>
        )}

        <AccountMenuButton
          icon={<LogOutIcon />}
          onClick={onLogout}
        >
          {t('выйти')}
        </AccountMenuButton>

      </Account>
    </Layout.Navbar>
  );
};

Navigation.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
  }),
  role: PropTypes.number,
  onLogout: PropTypes.func.isRequired,
  lunaPlatformIsAvailable: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  thermoIsAvailable: PropTypes.bool,
  lunaStreamsIsAvailable: PropTypes.bool,
  clementineAuthIsAvailable: PropTypes.bool,
  clementineDepartmentsIsAvailable: PropTypes.bool,
  notifierIsAvailable: PropTypes.bool,
  vlAccessIsAvailable: PropTypes.bool,
  vaReporterIsAvailable: PropTypes.bool,
  isFaceQualityAvailable: PropTypes.bool,
  can: PropTypes.func,

};
Navigation.defaultProps = {
  account: undefined,
  role: 0,
  isAuthenticated: false,
  lunaPlatformIsAvailable: false,
  thermoIsAvailable: false,
  lunaStreamsIsAvailable: false,
  clementineAuthIsAvailable: false,
  clementineDepartmentsIsAvailable: false,
  notifierIsAvailable: false,
  vlAccessIsAvailable: false,
  vaReporterIsAvailable: false,
  isFaceQualityAvailable: false,
  can: () => false,

};

export default connect((state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  account: selectAccountModel(state),
  role: selectAccountRole(state) ?? ROLE_OPTIONS[0],
  lunaPlatformIsAvailable: selectAppServiceState(state, 'lunaPlatform') === 'loaded',
  thermoIsAvailable: selectAppServiceState(state, 'thermo') === 'loaded',
  lunaStreamsIsAvailable: selectAppServiceState(state, 'lunaStreams') === 'loaded',
  vlAccessIsAvailable: selectAppServiceState(state, 'vlAccess') === 'loaded',
  clementineAuthIsAvailable: selectAppServiceState(state, 'clementineAuth') === 'loaded',
  vaReporterIsAvailable: selectAppServiceState(state, 'vaReporter') === 'loaded',
  clementineDepartmentsIsAvailable: selectAppServiceState(state, 'clementineDepartments') === 'loaded',
  notifierIsAvailable: selectAppServiceState(state, 'vlNotifier') === 'loaded',
  isFaceQualityAvailable: selectIsAppFeatureAvailable(state, 'estimations.face_quality'),
  can: viewerCan(state),
}), (dispatch) => ({
  onLogout: () => dispatch(logout),
}))(Navigation);
