import { createSelector } from '@reduxjs/toolkit';

export const selectEvent = (state) => state.event;

export const selectEventData = createSelector(
  selectEvent,
  ({ data }) => data,
);

export const selectEventPageState = createSelector(
  selectEvent,
  ({ state }) => state,
);

const trimByPage = ({ data, pageSize, pageIndex }) => {
  const trimStart = pageIndex * pageSize;
  const trimEnd = trimStart + pageSize;

  return data?.matchList?.slice(trimStart, trimEnd);
};

export const selectMatchResult = createSelector(
  selectEvent,
  trimByPage,
);
