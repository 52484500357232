import React, { forwardRef } from 'react';

import { LockedIcon } from '@vlabs/icons';
import cn from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import './BaseButton.sass';

const BaseButton = forwardRef(({
  variant,
  kind,
  children,
  fullWidth,
  icon,
  className,
  round,
  as,
  hasPermission = true,
  disabledTitle = i18n.t('uikit:control.btn.недостаточно прав.title'),
  disabled,
  title,
  ...props
}, externalRef) => {
  if (kind === 'neutral' && variant === 'dimmed') {
    // eslint-disable-next-line no-console
    console.debug('UIKIT: не стоит использовать кнопки dimmed neutral, так как они выглядят неинтерактивно');
  }

  const Component = as || 'button';
  const isDisabled = !hasPermission || disabled;

  const lockedIcon = (
    <span className={cn('LockIconWrapper', { 'LockIconWrapper--round': round })}>
      <LockedIcon className={cn('LockIcon', { 'LockIcon--round': round })} />
    </span>
  );

  return (
    <Component
      className={cn(
        className,
        'InteractionWrapper',
        {
          Button: true,
          Button_fullWidth: fullWidth,
          RoundButton: round,
          [`Button_${kind}`]: kind,
          [`Button_${variant}`]: variant,
          'Button--disabled': isDisabled,
        },
      )}
      disabled={isDisabled}
      ref={externalRef}
      title={!hasPermission ? disabledTitle : title}
      {...props}
    >
      {icon && (
        <span className="Icon Button__Icon">
          {icon}
        </span>
      )}
      {icon && children !== undefined && (<>&nbsp;</>)}

      <div className="Button__Content">
        {/* Если есть текст, т.е. обычная кнопка, то иконку размещаем перед текстом */}
        {!hasPermission && !round && lockedIcon}
        {children}
      </div>

      <div className="Button__Overlay InteractionOverlay" />
      {/* Если нет текста, т.е. кнопка-иконка, то иконку замочка размещаем поверх основной иконки действия */}
      {!hasPermission && round && lockedIcon}
    </Component>
  );
});

BaseButton.displayName = 'BaseButton';

BaseButton.propTypes = {
  variant: PropTypes.oneOf([
    'filled',
    'flat',
    'invisible',
    'outlined',
    'dimmed',
  ]),
  kind: PropTypes.oneOf([
    'primary',
    'negative',
    'attention',
    'positive',
    'neutral',
  ]),
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset',
  ]),
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  round: PropTypes.bool,
  hasPermission: PropTypes.bool,
  disabledTitle: PropTypes.string,
  title: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

BaseButton.defaultProps = {
  variant: 'filled',
  kind: 'primary',
  type: 'button',
  fullWidth: false,
  children: undefined,
  className: undefined,
  icon: undefined,
  disabled: undefined,
  round: undefined,
  hasPermission: true,
  disabledTitle: undefined,
  title: undefined,
  as: undefined,
};

export { BaseButton };
