import { permissions } from './permissions';

export const roles = {
  USER: 0,
  ADMIN: 2,
};

export const rbacRules = {
  [roles.USER]: {
    static: [
      permissions.face.view,
      permissions.list.view,
      permissions.event.view,
      permissions.verifier.view,
      permissions.source.view,
    ],
  },

  [roles.ADMIN]: {
    static: [
      ...Object.values(permissions).flatMap((permission) => [
        permission.view,
        permission.creation,
        permission.modification,
        permission.deletion,
        permission.matching,
        permission.iso,
        permission.sdk,
        permission.liveness,
        permission.createBatchProcessing,
        permission.allowed,
      ].filter(Boolean)),
    ],
  },
};
