import React, { useEffect, useRef } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { permissions } from '@vlabs/shared/config';
import { useSteppedForm } from '@vlabs/shared/hooks';
import { Control, useModal, Stepper, Page } from '@vlabs/uikit';
import cn from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { showResourceIntensiveTaskPopup } from '../showResourceIntensiveTaskPopup';
import { StepCallbacksPolicy } from '../steps/StepCallbacksPolicy';
import { StepCrossMatch } from './StepCrossMatch';
import '../styles/shared.styles.sass';

const defaultValues = {
  limit: 3,
  threshold: 50,
  notification_policy: {
    task_callbacks: [],
  },
};

// Разделение на два компонента необходимо чтобы избежать смешивания логики и
// чтобы избежать ошибок с активным шагом, который не сбрасывается при закрытии модального окна

const StepperModal = ({ onSubmit }) => {
  const { t } = useTranslation();
  const form = useForm({ defaultValues, mode: 'onChange' });
  const prevForm = useRef(defaultValues);
  const steps = [StepCrossMatch, StepCallbacksPolicy];

  useEffect(() => {
    prevForm.current = defaultValues;
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.reset, prevForm]);

  const stepper = useSteppedForm({ form, steps, onSubmit });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Page className={cn('TaskStepperModal__Page', 'CrossMatchForm')} title={t('tasks:кросс-матчинг')}>
          <Stepper {...stepper} className={cn('TaskStepperModal__StepperContent')}>
            {steps.map((Step) => <Step key={Step.name} />)}
          </Stepper>
        </Page>
      </form>
    </FormProvider>
  );
};

const CrossMatchWidgetComponent = ({ afterSubmit, can }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const crossmatchTask = async (params) => {
    await apiContainer.lunaClient.tasks.crossmatch(params);
    toast.info(i18next.t('tasks:подтверждение.кросс-матчинг'));
  };

  const submitForm = (values) => {
    const onConfirm = async () => {
      await crossmatchTask(values);
      modal.close();
      if (afterSubmit) afterSubmit();
    };
    showResourceIntensiveTaskPopup(onConfirm);
  };
  return (
    <>
      {modal.wrap(<StepperModal onSubmit={submitForm} />)}
      <Control.Button
        data-testid="crossMatchingButton"
        hasPermission={can(permissions.task.creation)}
        onClick={modal.open}
      >
        {t('tasks:кросс-матчинг')}
      </Control.Button>
    </>
  );
};

CrossMatchWidgetComponent.propTypes = {
  afterSubmit: PropTypes.func,
  can: PropTypes.func.isRequired,
};

CrossMatchWidgetComponent.defaultProps = {
  afterSubmit: undefined,
};

export const CrossMatchWidget = connect((state) => ({
  can: viewerCan(state),
}))(CrossMatchWidgetComponent);
