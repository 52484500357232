import React, { useState, useEffect, useCallback } from 'react';

import { CrossIcon, TriangleExclamationIcon } from '@vlabs/icons';
import { Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './DetectionSelector.module.sass';
import { ImagePicker } from './ImagePicker';
import ListPicker from './ListPicker';
import { detectionType, underlayImageType } from './types';

const DetectionSelector = ({
  photo,
  detections,
  onSelect,
  isMulti,
  reset,
  selectedId,
  error,
  withListSelector,
}) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  const isSelected = useCallback((detectionId) => {
    return selected.find((d) => d.id === detectionId);
  }, [selected]);

  const isAllSelected = detections.length > 0 && selected.length === detections.length;

  const onSelectHandler = useCallback((detectionId) => {
    if (!isMulti) {
      setSelected([detections.find((d) => d.id === detectionId)]);
      return;
    }

    if (isSelected(detectionId)) {
      setSelected(selected.filter((d) => d.id !== detectionId));
      return;
    }

    const currentDetection = detections.find((d) => d.id === detectionId);
    setSelected([...selected, currentDetection]);
  }, [detections, selected, isMulti, isSelected]);

  const onSelectAllHandler = useCallback(() => setSelected(detections), [detections]);
  const onUnselectAllHandler = useCallback(() => setSelected([]), []);

  useEffect(() => {
    if (!onSelect) return;
    onSelect(selected.map((d) => d.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (detections.length === 1 || selectedId) {
      setSelected([detections[0] || detections[selectedId]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detections]);

  return (
    <div className={st.Root}>
      {!!detections?.length && (
        <span className={st.Count}>
          {t('detectionSelector:количество обнаруженных лиц')}
          {': '}
          {detections.length}
        </span>
      )}
      {!!detections?.length && reset && (
        <Control.Button
          className={st.Reset}
          icon={<CrossIcon />}
          kind="negative"
          onClick={reset}
          variant="flat"
        />
      )}

      <div className={st.PickerContainer}>
        <ImagePicker
          detections={detections}
          isSelected={isSelected}
          onSelect={onSelectHandler}
          photo={photo}
        />
        {withListSelector && (
          <>
            <Divider small />
            <ListPicker
              detections={detections}
              isSelected={isSelected}
              onSelect={onSelectHandler}
            />
          </>
        )}
      </div>

      {isMulti && (
        <>
          <Divider small />
          <Control.Button
            className={st.SelectAllBtn}
            onClick={isAllSelected ? onUnselectAllHandler : onSelectAllHandler}
          >
            {isAllSelected ? t('Убрать все') : t('Выбрать все')}
          </Control.Button>
        </>
      )}

      {error && (
        <div className={st.ErrorMessage}>
          <div>
            <TriangleExclamationIcon />
          </div>
          {error || t('detectionSelector:необходимо выбрать хотя бы одно лицо')}
        </div>
      )}
    </div>
  );
};

DetectionSelector.propTypes = {
  photo: underlayImageType.isRequired,
  detections: PropTypes.arrayOf(detectionType),
  onSelect: PropTypes.func,
  isMulti: PropTypes.bool,
  withListSelector: PropTypes.bool,
  reset: PropTypes.func,
  selectedId: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

DetectionSelector.defaultProps = {
  detections: [],
  onSelect: undefined,
  isMulti: false,
  withListSelector: false,
  reset: undefined,
  selectedId: undefined,
  error: undefined,
};

export default DetectionSelector;
