import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import { GoToCell } from '@vlabs/shared/components/table';
import FaceInfoCell from '@vlabs/shared/legacy-components/face-info-cell/FaceInfoCell';
import { routes } from '@vlabs/shared/navigation/routes';
import { Table, Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { selectFacesWithTheSameExternalId } from '../selectors';
import { updateFacesPage } from '../store';
import st from './tabs.module.sass';

const Info = ({ title }) => (
  <div className={st.InfoRoot}>
    <CircleInfoIcon className="StaticIcon" />
    <div className={st.Title}>{title}</div>
  </div>
);

const FaceSampleCell = ({ value }) => <Sample className={st.Image} {...value} />;

function FacesTableTab({
  external_id,
  faces,
  setPageIndex,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={st.TablePanel}>
      {!faces.count && external_id && (
        <Info title={t('faces:лица с тем же внешним id отсутствуют')} />
      )}
      {!external_id && (
        <Info title={t('faces:внешний идентификатор отсутствует')} />
      )}
      {faces.count > 0 && (
        <>
          <Table
            columns={[
              {
                id: 1,
                accessor: (value) => ({ image: value.uiAvatarURL }),
                Cell: FaceSampleCell,
                width: 70,
              },
              {
                id: 2,
                // FIXME: непонятный компонент, либо это ячейка таблицы и она должна принимать как row, так и value
                // либо это отдельный компонент, который можно сделать частью *Cell компонента
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ row }) => <FaceInfoCell className={st.RowInfoWidth} {...row} />,
                width: 170,
              },
              {
                id: 3,
                Cell: GoToCell,
                accessor: ({ face_id: faceId }) => (faceId ? `/faces/${faceId}` : undefined),
                width: 10,
              },
            ]}
            data={faces?.data}
            data-testid="faceTableTab"
            noHead
            pageIndex={faces.pageIndex}
            pageSize={3}
            pageSizeOptions={[3]}
            paginationType="offsetBased"
            setPageIndex={setPageIndex}
          />
          <div className={st.GoTo}>
            <Control.Button
              data-testid="faceTableButton"
              onClick={() => history.push(generatePath(routes.externalId.read, { externalId: external_id }))}
              variant="outlined"
            >
              {t('faces:посмотреть все лица с тем же внешним id')}
            </Control.Button>
          </div>

        </>
      )}
    </div>
  );
}

FacesTableTab.propTypes = {
  external_id: PropTypes.string,
  faces: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    pageIndex: PropTypes.number,
    count: PropTypes.number,
  }),
  setPageIndex: PropTypes.func,
};

FacesTableTab.defaultProps = {
  external_id: '',
  faces: {},
  setPageIndex: undefined,
};

const $FacesTableTab = connect(
  (state) => ({
    faces: selectFacesWithTheSameExternalId(state),
  }),
  (dispatch) => ({
    setPageIndex: (pageIndex) => dispatch(updateFacesPage({ pageIndex })),
  }),
)(FacesTableTab);

export { $FacesTableTab as FacesTableTab };
