import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../controls/button/Button';
import { IndeterminatePagination } from '../pagination/indeterminate-pagination/IndeterminatePagination';
import { Pagination } from '../pagination/pagination/Pagination';
import { SizeOnlyPagination } from '../pagination/size-only-pagination/SizeOnlyPagination';
import { selectColumn } from './actionColumns';
import { TableContext } from './table-context';

import './TableBottomPanel.sass';

const TableBottomPanel = ({
  scrollToTop,
  CustomPagination,
  extraButtons,
}) => {
  const { t } = useTranslation();
  const context = useContext(TableContext);
  const {
    selectedFlatRows,
    state: { pageIndex, pageSize },
    pageCount,
    setPageSize,
    pageSizeOptions,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    canFirstPage,
    firstPage,
    canLastPage,
    lastPage,
    gotoPage,
    paginationType,
    actions,
    visibleColumns,
  } = context;

  const withScrollToTop = (fn) => (...args) => {
    scrollToTop();
    fn(...args);
  };

  const $nextPage = withScrollToTop(nextPage);
  const $previousPage = withScrollToTop(previousPage);
  const $firstPage = withScrollToTop(firstPage);
  const $lastPage = withScrollToTop(lastPage);
  const $gotoPage = withScrollToTop(gotoPage);

  const onAddRow = actions.onAddRow?.handler;
  const hasAddRowPermission = actions.onAddRow?.hasPermission();
  const addingButtonLabel = actions.onAddRow?.label;

  const selectedRows = selectedFlatRows.map(({ original }) => original);

  const rowSelectionIsEnabled = visibleColumns.findIndex(({ id }) => id === selectColumn.id) >= 0;

  const onDeleteSelectedRows = actions.onDeleteRow?.handler;
  const hasDeleteSelectedRowsPermission = actions.onDeleteRow?.hasPermission();
  const deletingButtonLabel = actions.onDeleteRow?.label;

  if (
    paginationType === undefined
      && CustomPagination === undefined
      && onAddRow === undefined
      && !rowSelectionIsEnabled
  ) return null;

  return (
    <div className="TableBottomPanel">
      <div className="TableBottomPanel__Controls">
        {onAddRow && (
          <Button
            data-testid="table.addButton"
            hasPermission={hasAddRowPermission}
            onClick={() => onAddRow()}
          >
            {addingButtonLabel || t('uikit:table.действия.добавление нового элемента')}
          </Button>
        )}
        {extraButtons && extraButtons(context)}
        {rowSelectionIsEnabled && onDeleteSelectedRows && (
          <Button
            data-testid="table.deleteButton"
            disabled={selectedRows.length === 0}
            hasPermission={hasDeleteSelectedRowsPermission}
            kind="negative"
            onClick={() => onDeleteSelectedRows(selectedRows)}
            onKeyPress={() => onDeleteSelectedRows(selectedRows)}
          >
            {deletingButtonLabel || t('uikit:table.действия.удаление выбранных элементов')}
          </Button>
        )}
      </div>

      <div className="TableBottomPanel__Pagination">
        {paginationType === 'timeBased' && (
          <IndeterminatePagination
            canFirstPage={canFirstPage}
            canLastPage={canLastPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            firstPage={$firstPage}
            lastPage={$lastPage}
            nextPage={$nextPage}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            previousPage={$previousPage}
            setPageSize={setPageSize}
          />
        )}
        {paginationType === 'offsetBased' && (
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={$gotoPage}
            nextPage={$nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            previousPage={$previousPage}
            setPageSize={setPageSize}
          />
        )}
        {paginationType === 'sizeOnly' && (
          <SizeOnlyPagination
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            setPageSize={setPageSize}
          />
        )}
        {CustomPagination && (
          <CustomPagination
            context={context}
            firstPage={$firstPage}
            gotoPage={$gotoPage}
            lastPage={$lastPage}
            nextPage={$nextPage}
            previousPage={$previousPage}
          />
        )}
      </div>
    </div>
  );
};

TableBottomPanel.propTypes = {
  scrollToTop: PropTypes.func.isRequired,
  CustomPagination: PropTypes.func,
  extraButtons: PropTypes.func,
};

TableBottomPanel.defaultProps = {
  CustomPagination: undefined,
  extraButtons: undefined,
};

export { TableBottomPanel };
