import React from 'react';

import { CircleCrossIcon } from '@vlabs/icons';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { calcBadgeType } from '@vlabs/shared/utils';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { EventSample } from './EventSample';

import './TopMatchCell.sass';

export const EventSamplesCell = ({
  original,
  onEventClick,
  ...props
}) => {
  const badgeColor = calcBadgeType(original?.top_match?.similarity);
  const { t } = useTranslation();

  return (
    <div className={cn({
      TopMatch_wrapper: true,
      [`TopMatchCard_color_${badgeColor}`]: original?.top_match,
    })}
    >
      <EventSample
        event={original}
        eventType="face"
        {...props}
        onEventClick={onEventClick}
      />
      <EventSample
        event={original}
        eventType="body"
        {...props}
        onEventClick={onEventClick}
      />

      {!original.face_detections && !original.body_detections && (
        <EmptyImage
          icon={<CircleCrossIcon />}
          messageText={t('объект не обнаружен')}
          onImageClick={onEventClick}
        />
      )}
    </div>
  );
};

EventSamplesCell.propTypes = {
  original: PropTypes.objectOf(PropTypes.any),
  onEventClick: PropTypes.func,
};

EventSamplesCell.defaultProps = {
  original: {},
  onEventClick: undefined,
};
