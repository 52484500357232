import { apiContainer } from '@vlabs/api-bindings';
import { getAvatarsByFaceIds, getEventInfoByType, getListsByFaceIds } from '@vlabs/shared/requests';

const filterIsExistId = async (id, checkIsExist) => {
  if (!id) return undefined;
  return (await checkIsExist(id)) ? id : undefined;
};

const filterIsExistFaceId = (id) => filterIsExistId(id, apiContainer.lunaClient.faces.isExist);
const filterIsExistEventId = (id) => filterIsExistId(id, apiContainer.lunaClient.events.isExist);

export async function getEventCardData(rawMatchResult) {
  if (!rawMatchResult) return [];

  const faceIds = (rawMatchResult.flatMap(({ candidates }) => candidates)
    .filter(({ face }) => face)
    .map(({ face }) => face.face_id));

  const eventIds = (rawMatchResult.flatMap(({ candidates }) => candidates)
    .filter(({ event }) => event)
    .map(({ event }) => event.event_id));

  const [filteredFaceIds, filteredEventIds] = await Promise.all([
    Promise.all(faceIds.map(filterIsExistFaceId)).then((ids) => ids.filter(Boolean)),
    Promise.all(eventIds.map(filterIsExistEventId)).then((ids) => ids.filter(Boolean)),
  ]);

  const [facesLists, faceAvatars, eventInfo] = await Promise.all([
    getListsByFaceIds(faceIds),
    getAvatarsByFaceIds(faceIds),
    getEventInfoByType(eventIds),
  ]);

  const candidatesList = rawMatchResult.flatMap(({ label, candidates }) => candidates.map((match) => {
    const { face, event } = match;
    const faceId = face?.face_id;
    const eventId = event?.event_id;

    if (filteredFaceIds.includes(faceId)) {
      return {
        label,
        similarity: match.similarity,
        lists: facesLists[faceId],
        type: 'face',
        ...face,
        ...(faceAvatars[faceId] && { avatar: faceAvatars[faceId] }),
      };
    }

    if (filteredEventIds.includes(eventId)) {
      return {
        label,
        similarity: match.similarity,
        type: eventInfo[eventId].type,
        ...event,
        ...(eventInfo[eventId] && { avatar: eventInfo[eventId].avatar }),
      };
    }

    return {
      isDeleted: true,
      label,
      similarity: match.similarity,
      type: face ? 'face' : 'event',
      ...face,
      ...event,
    };
  }),
  );

  return candidatesList.sort((a, b) => b.similarity - a.similarity);
}
