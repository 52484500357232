import React from 'react';

import { DetectionSelector } from '@vlabs/shared/components/detection-selector';
import { imageTypes } from '@vlabs/shared/config';
import { selectMaxImageSize } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Control } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFaceDetectionsFromFile } from '../requests/getFaceDetectionsFromFile';

const PhotoUploadFormComponent = ({
  maxImageSize,
}) => {
  const { t } = useTranslation();
  const { form, onResetFile, setSampleId, detections, setDetections } = useFormContext();
  const file = useWatch({ name: 'file', control: form.control });

  const fetchFaceDetections = async (file) => {
    const faceDetections = await getFaceDetectionsFromFile(file.raw);

    if (faceDetections.length) {
      setDetections(faceDetections);
    } else {
      form.setValue('file', '');
      form.setError('file', { message: t('выбранное изображение не содержит лиц') });
    }
  };

  const onFileSelect = (newFile) => {
    if (!newFile) return;
    form.clearErrors('file');
    form.setValue('file', newFile);
    fetchFaceDetections(newFile);
  };

  const onSelectSampleId = (value) => {
    setSampleId(value);
    form.clearErrors('file');
  };

  return (
    <duv>
      {detections.length === 0 && (
        <Control.FileDropzone
          data-testid="fileSelector"
          id="file"
          onChangeValue={onFileSelect}
          {...form.register('file', {
            required: !file && validate.required(), // FIXME: перепроверить как работает без проверки на файл
          })}
          error={form?.formState?.errors?.file?.message}
          maxImageSize={maxImageSize}
          onlyImages
          supportedImageTypes={imageTypes}
        />
      )}
      {detections.length > 0 && (
        <DetectionSelector
          detections={detections}
          error={form?.formState?.errors?.file?.message}
          onSelect={onSelectSampleId}
          photo={file.binary}
          reset={onResetFile}
        />
      )}
    </duv>
  );
};

export const PhotoUploadForm = connect(
  (state) => ({
    maxImageSize: selectMaxImageSize(state),
  }),
)(PhotoUploadFormComponent);
