import React from 'react';

import { optionPropType } from '@vlabs/shared';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import { Control } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import st from './ListLink.module.sass';

const ListLink = ({
  lists,
  listOptions,
}) => {
  if (!lists?.length) {
    return (<div>-</div>);
  }

  return (
    // FIXME поправить работу со стилями => !displayType
    <div className={cn(
      {
        [st.ListLink]: true,
        [st.ListLink_multiple]: lists.length > 2,
      })}
    >
      {lists.map((listId, index) => (
        <Control.Link
          data-testid={`listLink${index + 1}`}
          key={listId}
          to={generatePath(routes.lists.read, { listId })}
        >
          {listOptions?.find(({ value }) => value === listId)?.label || listId}
        </Control.Link>
      ))}
    </div>
  );
};

ListLink.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.string),
  listOptions: PropTypes.arrayOf(optionPropType),
};
ListLink.defaultProps = {
  lists: [],
  listOptions: [],
};

export default connect((state) => ({
  listOptions: selectListOptions(state),
}))(ListLink);
