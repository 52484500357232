import React, { useEffect, useState } from 'react';

import { Pages } from '@vlabs/uikit';
import { UnauthorizedPage } from '@vlabs/uikit/src/pages/unauthorized-page/UnauthorizedPage';
import { useStore, useSelector } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

const STATES = {
  init: 'init',
  loading: 'loading',
  failed: 'failed',
  notFound: 'notFound',
  loaded: 'loaded',
  unauthorized: 'unauthorized',
};

export const routeControllerHOC = ({
  onInit,
  onLoading,
  onLoaded,
  onFailed,
  onUnauthorized,
  componentState,
  authRule,
  ...componentProps
} = {}) => (WrappedComponent) => (props) => {
  const store = useStore();
  const can = useSelector(viewerCan);
  const [state, setState] = useState(STATES.init);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [component, setComponent] = useState(null);

  const match = props?.match;

  useEffect(() => {
    if (!match.url) return;
    setState(STATES.init);
  }, [match.url]);

  useEffect(() => {
    if (!authRule) {
      setIsAuthorized(true);
      return;
    }

    setIsAuthorized(can(...authRule));
  }, [can]);

  useEffect(() => {
    if (!isAuthorized) {
      setState(STATES.unauthorized);
      return undefined;
    }
    if (componentState === undefined) {
      setState(STATES.loaded);
      return undefined;
    }

    if (componentState && typeof componentState === 'string') {
      if (STATES[componentState]) setState(componentState);
      else setState(STATES.failed);
      return undefined;
    }

    let unsubscribe;
    if (componentState && typeof componentState === 'function') {
      let temporaryState = componentState(store.getState(), props);
      if (STATES[temporaryState]) setState(temporaryState);
      unsubscribe = store.subscribe(() => {
        temporaryState = componentState(store.getState(), props);
        if (STATES[temporaryState]) setState(temporaryState);
      });
    }

    return unsubscribe;
  }, [isAuthorized, store, props]);

  useEffect(() => {
    switch (state) {
      case STATES.init:
        if (onInit) onInit(store, props);
        setComponent(null);
        break;
      case STATES.loading:
        if (onLoading) onLoading(store, props);
        setComponent(<WrappedComponent {...props} {...componentProps} can={can} />);
        break;
      case STATES.loaded:
        if (onLoaded) onLoaded(store, props);
        setComponent(<WrappedComponent {...props} {...componentProps} can={can} />);
        break;
      case STATES.failed:
        setComponent(<Pages.NotFoundPage />);
        break;
      case STATES.notFound:
        setComponent(<Pages.NotFoundPage />);
        break;
      case STATES.unauthorized:
        if (onUnauthorized) onUnauthorized(store, props);
        setComponent(<UnauthorizedPage />);
        break;
      default:
        setComponent(null);
        break;
    }
    // FIXME: проверить зависимости
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, props, store]);

  return component;
};

export default routeControllerHOC;
