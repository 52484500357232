import React, { useEffect, useRef, useState } from 'react';

import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import {
  useFilters,
  useFlexLayout,
  usePagination as useOffsetPagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { useTimePagination } from '../../hooks/useTimePagination';
import { addCellStyles } from './styles';
import { TableContext } from './table-context';
import { TableBody } from './TableBody';
import { TableBottomPanel } from './TableBottomPanel';
import { TableHead } from './TableHead';
import { onActionsPropType } from './types';
import { prepareActions } from './utils';

const Table = ({
  columns,
  data,
  meta,
  pageIndex: pageIndexProp,
  setPageIndex: setPageIndexProp,
  pageSize: pageSizeProp,
  pageSizeOptions,
  setPageSize: setPageSizeProp,
  filters: filtersProp,
  setFilters: setFiltersProp,
  onActions,
  highlight,
  paginationType,
  CustomPagination,
  CustomBottomPanel,
  CustomTopPanel,
  noBottomPanel,
  CustomBody,
  CustomHead,
  noHead,
  CustomRow,
  setSince,
  setUntil,
  disableSortBy,
  rowProps,
  extraButtons,
  onSelectionChange,
}) => {
  const [pageCount, setPageCount] = useState(-1);
  const { count, sinceAvailable, untilAvailable } = meta;

  const tableRef = useRef();

  const scrollToTop = () => {
    if (!tableRef.current) return;

    const firstRow = tableRef.current.querySelector('[data-testid="tableRow"]');

    // true означает скролл к верхней части
    if (firstRow) {
      firstRow.scrollIntoView(true);
    } else {
      tableRef.current.scrollIntoView(true);
    }
  };

  useEffect(() => {
    if (count === undefined || count === null || !pageSizeProp) return;
    setPageCount(Math.ceil(count / pageSizeProp));
  }, [count, pageSizeProp]);

  const hooks = [useFilters];
  if (!disableSortBy) hooks.push(useSortBy);
  if (paginationType === 'timeBased') hooks.push(useTimePagination);
  if (CustomPagination || paginationType === 'offsetBased' || paginationType === 'sizeOnly') {
    hooks.push(useOffsetPagination);
  }
  hooks.push(useRowSelect);
  hooks.push(useFlexLayout);

  const table = useTable({
    // useTable props
    data,
    columns,
    defaultColumn: {
      minWidth: 40,
      width: 150,
      maxWidth: 150,
    },
    initialState: {
      filters: filtersProp,
      pageSize: pageSizeProp,
      pageIndex: pageIndexProp,
    },

    // usePagination props
    pageCount,
    manualPagination: true,

    // useFilters props
    manualFilters: true,

    // useSortBy props
    disableSortBy,

    // useTimePagination props
    sinceAvailable,
    untilAvailable,

    // custom
    onActions,
  }, ...hooks);

  const {
    state: {
      pageIndex,
      pageSince,
      pageUntil,
      filters,
    },
    selectedFlatRows,
    setAllFilters,
    gotoPage,
    setPageSize,
    tableProps,
  } = table;

  useEffect(() => {
    if (!onSelectionChange) return;
    onSelectionChange(selectedFlatRows);
  }, [onSelectionChange, selectedFlatRows]);

  useEffect(() => {
    if (setSince) setSince(pageSince);
  }, [pageSince, setSince]);

  useEffect(() => {
    if (setUntil) setUntil(pageUntil);
  }, [pageUntil, setUntil]);

  useEffect(() => {
    if (setPageIndexProp) setPageIndexProp(pageIndex);
  }, [pageIndex, setPageIndexProp]);

  useEffect(() => {
    if (setFiltersProp) setFiltersProp(filters);
  }, [filters, setFiltersProp]);

  useEffect(() => {
    if (isEqual(filtersProp, filters)) return;
    if (filtersProp.length === 0 && filters.length > 0) setAllFilters([]);
    if (gotoPage) gotoPage(0);
  }, [filtersProp]);

  useEffect(() => {
    if (pageIndexProp === 0 && gotoPage) gotoPage(0);
  }, [pageIndexProp]);

  useEffect(() => {
    if (!setPageSize) return;
    setPageSize(pageSizeProp);
  }, [pageSizeProp]);

  return (
    // FIXME: надо что-то придумать с мемоизацией контекста,
    //  в лоб не получается никак, потому что результат хука useTable
    //  мемоизировать нельзя. Это должно улучшить перфоманс
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TableContext.Provider value={{
      ...table,
      onActions,
      pageSizeOptions,
      highlight,
      setPageSize: setPageSizeProp,
      actions: prepareActions(onActions),
      paginationType,
      rowProps,
      addCellStyles,
    }}
    >
      <div
        {...tableProps}
        className="Table"
        data-testid="tableRoot"
        ref={tableRef}
      >
        {CustomTopPanel && (
          <div className="TableTopPanel">
            <CustomTopPanel scrollToTop={scrollToTop} TableContext={TableContext} />
          </div>
        )}
        <div className="Table__ScrollContainer">
          {CustomHead && <CustomHead TableContext={TableContext} />}
          {!noHead && !CustomHead && <TableHead />}

          {CustomBody && <CustomBody TableContext={TableContext} />}
          {!CustomBody && <TableBody CustomRow={CustomRow} />}
        </div>

        {CustomBottomPanel && <CustomBottomPanel scrollToTop={scrollToTop} TableContext={TableContext} />}
        {!noBottomPanel && !CustomBottomPanel && (
          <TableBottomPanel
            CustomPagination={CustomPagination}
            extraButtons={extraButtons}
            scrollToTop={scrollToTop}
          />
        )}
      </div>
    </TableContext.Provider>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  meta: PropTypes.shape({
    count: PropTypes.number,
    sinceAvailable: PropTypes.bool,
    untilAvailable: PropTypes.bool,
  }),
  columns: PropTypes.arrayOf(PropTypes.any),
  pageIndex: PropTypes.number,
  setPageIndex: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  setPageSize: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.any),
  setFilters: PropTypes.func,
  onActions: PropTypes.shape(onActionsPropType),
  highlight: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  paginationType: PropTypes.oneOf([
    'timeBased',
    'offsetBased',
    'sizeOnly',
  ]),
  CustomPagination: PropTypes.func,
  CustomBottomPanel: PropTypes.func,
  CustomTopPanel: PropTypes.func,
  noBottomPanel: PropTypes.bool,
  CustomBody: PropTypes.func,
  CustomHead: PropTypes.func,
  noHead: PropTypes.bool,
  CustomRow: PropTypes.func,
  setSince: PropTypes.func,
  setUntil: PropTypes.func,
  disableSortBy: PropTypes.bool,
  rowProps: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  extraButtons: PropTypes.func,
  onSelectionChange: PropTypes.func,
};

Table.defaultProps = {
  data: [],
  meta: {
    count: undefined,
    sinceAvailable: undefined,
    untilAvailable: undefined,
  },
  columns: [],
  pageIndex: 0,
  setPageIndex: undefined,
  pageSize: 25,
  pageSizeOptions: [25],
  setPageSize: undefined,
  filters: [],
  setFilters: undefined,
  onActions: {},
  highlight: undefined,
  paginationType: undefined,
  CustomPagination: undefined,
  CustomBottomPanel: undefined,
  CustomTopPanel: undefined,
  CustomBody: undefined,
  CustomHead: undefined,
  CustomRow: undefined,
  noHead: undefined,
  noBottomPanel: undefined,
  setSince: undefined,
  setUntil: undefined,
  disableSortBy: true,
  rowProps: undefined,
  extraButtons: undefined,
  onSelectionChange: undefined,
};

export { Table };
