export const prepareActions = (actionHandlers) => {
  const actions = {};

  Object.entries(actionHandlers).forEach(([actionName, actionData]) => {
    if (!actionData) return;

    const { handler, can = true } = actionData;

    actions[actionName] = {
      label: actionData.label,
      handler,
      hasPermission: () => {
        if (typeof can === 'function') {
          return can();
        }
        return can;
      },
    };
  });

  return actions;
};
