// FIXME: максимально похоже на eventToEvent мб чет переиспользовать? 
export const read = (lunaClient) => (matchResult) => {
  const addMainDetections = ({ event, similarity }) => {
    const getFullFrame = (detection) => {
      if (!detection?.image_origin) return undefined;
      return {
        ...detection,
        uiFullFrame: lunaClient.images.getURL(detection.image_origin),
      };
    };

    const body_detections = event?.body_detections?.map((detection) => ({
      ...detection,
      uiSampleURL: lunaClient.samples.getURL('bodies', detection.sample_id),
    })) || null;
    const uiBodyDetection = body_detections?.find(({ uiSampleURL }) => uiSampleURL) || null;

    const face_detections = event?.face_detections?.map((detection) => ({
      ...detection,
      uiSampleURL: lunaClient.samples.getURL('faces', detection?.sample_id),
    })) || null;
    const uiFaceDetection = face_detections?.find(({ uiSampleURL }) => uiSampleURL) || null;

    const faceDetectionWithFullFrame = face_detections?.find(({ image_origin }) => image_origin?.startsWith('/6/images')) || null;
    const bodyDetectionWithFullFrame = body_detections?.find(({ image_origin }) => image_origin?.startsWith('/6/images')) || null;
    const detectionWithUiFullFrame = getFullFrame(faceDetectionWithFullFrame)
      || getFullFrame(bodyDetectionWithFullFrame);

    return ({
      similarity,
      event: {
        ...event,
        origin: 'events',
        detectionWithUiFullFrame,
        uiFaceDetection,
        uiBodyDetection,
      },
    });
  };
  const addMainFaceDetection = ({ face, similarity }) => {
    const uiAvatarURL = lunaClient.faces.getAvatarURL(face.avatar);

    return ({
      similarity,
      face: {
        ...face,
        origin: 'faces',
        uiAvatarURL,
      },
    });
  };

  const handleResult = (result, filters) => {
    if (result === undefined) return [];
    let $result = [];

    if (filters.origin === 'events') {
      $result = result?.map(addMainDetections);
    }

    if (filters.origin === 'faces') {
      $result = result?.map(addMainFaceDetection);
    }

    return $result;
  };

  return {
    reference: matchResult.reference,
    matches: matchResult.matches.map(({ filters, result }) => ({ filters, result: handleResult(result, filters) })),
  };
};
