import React, { useEffect, useMemo, useRef } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { optionPropType } from '@vlabs/shared';
import { permissions } from '@vlabs/shared/config';
import { useSteppedForm } from '@vlabs/shared/hooks';
import { Stepper, Page, Control, useModal } from '@vlabs/uikit';
import cn from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { viewerCan, selectAccountEmail, selectAccountLogin } from '@vlabs/pages/auth/selectors';

import { showResourceIntensiveTaskPopup } from '../showResourceIntensiveTaskPopup';
import { StepCallbacksPolicy } from '../steps/StepCallbacksPolicy';
import { getDefaultValuesByObjectType } from './defaultValues';
import { StepColumns, StepEventsFilters, StepFacesFilters } from './steps';
import './ExporterForm.sass';
import '../styles/shared.styles.sass';

const titleByObjectType = {
  faces: i18next.t('tasks:экспорт лиц'),
  events: i18next.t('tasks:экспорт событий'),
};

const stepByObjectType = {
  faces: StepFacesFilters,
  events: StepEventsFilters,
};

// Разделение на два компонента необходимо чтобы избежать смешивания логики и
// чтобы избежать ошибок с активным шагом, который не сбрасывается при закрытии модального окна

const StepperModalComponent = ({ filters, email, onSubmit, objectType }) => {
  const steps = [stepByObjectType[objectType], StepColumns, StepCallbacksPolicy];
  const defaultValues = useMemo(() => ({
    ...getDefaultValuesByObjectType(objectType, filters),
    description: email,
  }), [objectType, filters, email]);

  const form = useForm({ defaultValues, mode: 'onChange' });
  const prevForm = useRef(defaultValues);

  useEffect(() => {
    prevForm.current = defaultValues;
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.reset, defaultValues, prevForm]);

  const stepper = useSteppedForm({ form, steps, onSubmit });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Page className={cn('TaskStepperModal__Page', 'ExporterForm')} title={titleByObjectType[objectType]}>
          <Stepper {...stepper} className={cn('TaskStepperModal__StepperContent')}>
            {steps.map((Step) => <Step key={Step.name} />)}
          </Stepper>
        </Page>
      </form>
    </FormProvider>
  );
};

const StepperModal = connect((state) => ({
  email: selectAccountLogin(state) ?? selectAccountEmail(state),
}))(StepperModalComponent);

const ExporterTaskWidgetComponent = ({ afterSubmit, objectType, filters, can }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const exporterTask = async ({ meta = [], ...props }) => {
    await apiContainer.lunaClient.tasks.exporter({ meta, ...props });
    toast.info(t('tasks:задача на экспорт создана'));
  };
  const submitForm = (values) => {
    const onConfirm = async () => {
      await exporterTask(values);
      modal.close();
      if (afterSubmit) afterSubmit();
    };
    showResourceIntensiveTaskPopup(onConfirm);
  };
  return (
    <>
      {modal.wrap(<StepperModal filters={filters} objectType={objectType} onSubmit={submitForm} />)}
      <Control.Button
        data-testid={`${objectType}ExportButton`}
        hasPermission={can(permissions.task.creation)}
        onClick={modal.open}
      >
        {titleByObjectType[objectType]}
      </Control.Button>
    </>
  );
};

ExporterTaskWidgetComponent.propTypes = {
  can: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func,
  objectType: PropTypes.oneOf(['faces', 'events']).isRequired,
  filters: PropTypes.shape({
    list_id: PropTypes.arrayOf(optionPropType),
  }),
};

ExporterTaskWidgetComponent.defaultProps = {
  afterSubmit: undefined,
  filters: {},
};

export const ExporterTaskWidget = connect((state) => ({
  can: viewerCan(state),
}))(ExporterTaskWidgetComponent);
