import React, { useState, useEffect } from 'react';

import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { permissions } from '@vlabs/shared/config';
import { calcSimilarityPercent, calcBadgeType } from '@vlabs/shared/utils';
import { Badge } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import st from './Sample.module.sass';

const SampleComponent = ({
  controls,
  image,
  similarity,
  className,
  isDeleted,
  type,
  can,
}) => {
  const { t } = useTranslation();
  const [isEmpty, setIsEmpty] = useState(!image);
  const [isControlsVisible, setControlsVisible] = useState(false);
  const canFaceSampleView = can(permissions.faceSample.view);

  useEffect(() => {
    if (!canFaceSampleView) {
      setControlsVisible(true);
    }
  }, [canFaceSampleView]);

  const messageTextByType = {
    face: t('faces:лицо удалено'),
    event: t('events:событие удалено'),
    body: t('bodies:тело удалено'),
  };

  const renderContent = () => {
    if (!canFaceSampleView) {
      return <EmptyImage className={st.Empty_Image} messageText={t('faces:недостаточно прав для просмотра образца лица')} />;
    }

    if (isDeleted) {
      return <EmptyImage className={st.Empty_Image} messageText={messageTextByType[type]} />;
    }

    if (isEmpty) {
      return <EmptyImage className={st.Empty_Image} />;
    }

    return (
      <img
        alt="sample"
        className={cn(st.Image, st[`Image_${type}`])}
        onError={() => setIsEmpty(true)}
        onLoad={() => setControlsVisible(true)}
        src={image}
      />
    );
  };

  return (
    <div className={cn(st.Wrapper, st[`Wrapper_${type}`], className)}>
      {renderContent()}
      {similarity && (
        <Badge
          className={st.Badge}
          kind={calcBadgeType(similarity)}
          value={calcSimilarityPercent(similarity)}
        />
      )}
      {isControlsVisible && controls && (
        <div className={st.Controls}>{controls}</div>
      )}
    </div>
  );
};

SampleComponent.propTypes = {
  controls: PropTypes.node,
  image: PropTypes.string,
  similarity: PropTypes.number,
  className: PropTypes.string,
  isDeleted: PropTypes.bool,
  type: PropTypes.string,
  can: PropTypes.func.isRequired,
};

SampleComponent.defaultProps = {
  controls: undefined,
  image: undefined,
  similarity: null,
  className: undefined,
  isDeleted: undefined,
  type: undefined,
};

export const Sample = connect((state) => ({
  can: viewerCan(state),
}))(SampleComponent);
