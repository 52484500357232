import { apiContainer } from '@vlabs/api-bindings';

export async function getExistedEvents(eventIds = []) {
  const $eventIds = Array.isArray(eventIds) ? eventIds : [eventIds];

  if ($eventIds.length === 0) return {};
  const eventsStatus = {};

  const promises = $eventIds.map((id) => {
    return apiContainer.lunaClient.events.isExist(id);
  });

  const response = await Promise.allSettled(promises);

  $eventIds.forEach((eventId, i) => {
    eventsStatus[eventId] = response[i].value;
  });

  return eventsStatus;
}
