import { apiContainer } from '@vlabs/api-bindings';

export async function getExistedFaces(faceIds = []) {
  const $faceIds = Array.isArray(faceIds) ? faceIds : [faceIds];

  if ($faceIds.length === 0) return {};
  const facesStatus = {};

  const promises = $faceIds.map((id) => {
    return apiContainer.lunaClient.faces.isExist(id);
  });

  const response = await Promise.allSettled(promises);

  $faceIds.forEach((faceId, i) => {
    facesStatus[faceId] = response[i].value;
  });

  return facesStatus;
}
