import React, { useCallback } from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { selectIsAppFeatureAvailable } from '@vlabs/shared/selectors/appSelectors';
import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper, Fold, Tooltip } from '@vlabs/uikit';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext, useWatch, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useUpdateAllPoliciesField } from '../hooks';
import st from './FaceQualitySubForm.module.sass';

const LivenessSubFormComponent = ({
  isLivenessAvailable,
}) => {
  const { t } = useTranslation();
  const { control, register } = useFormContext();
  const { errors } = useFormState();
  const estimateLiveness = useWatch({ name: 'estimate_liveness.estimate' });

  useUpdateAllPoliciesField({
    on: useCallback(() => estimateLiveness === false, [estimateLiveness]),
    path: 'filters.liveness',
    value: [],
    updateIf: useCallback((v) => !isEmpty(v), []),
  });

  const livenessTitle = (
    <div className={st.FoldTitle}>
      <h5>{t('handlers:policies.detect_policy.estimate_liveness.estimate')}</h5>
      {!isLivenessAvailable && (
        <Tooltip
          className="Tooltip_ui"
          data-tooltip-content={t('handlers:policies.detect_policy.tooltip.liveness license is required')}
          tooltipProps={{ effect: 'solid' }}
        >
          <CircleInfoIcon />
        </Tooltip>
      )}
    </div>
  );
  return (
    <Fold
      control={control}
      disabled={!isLivenessAvailable}
      isOpen={useWatch({ name: 'estimate_liveness.estimate' })}
      name="estimate_liveness.estimate"
      switch
      testId="estimate_liveness"
      title={livenessTitle}
    >
      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        title={t('handlers:policies.detect_policy.estimate_liveness.liveness_threshold')}
      >
        <Control.Input
          {...register('estimate_liveness.liveness_threshold', {
            min: validate.gte(0),
            max: validate.lte(1),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName="MatchPolicy_Input"
        title={t('handlers:policies.detect_policy.estimate_liveness.quality_threshold')}
      >
        <Control.Input
          {...register('estimate_liveness.quality_threshold', {
            min: validate.gte(0),
            max: validate.lte(1),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
};

LivenessSubFormComponent.propTypes = {
  isLivenessAvailable: PropTypes.bool,
};
LivenessSubFormComponent.defaultProps = {
  isLivenessAvailable: false,
};

export const LivenessSubForm = connect((state) => ({
  isLivenessAvailable: selectIsAppFeatureAvailable(state, 'estimations.liveness'),
}))(LivenessSubFormComponent);
