import { apiContainer } from '@vlabs/api-bindings';

export async function getEventInfoByType(eventIds = []) {
  if (eventIds.length === 0) return {};

  const events = await apiContainer.lunaClient.events.getAll({
    event_ids: eventIds,
    targets: 'face_detections',
    page_size: eventIds.length,
  });

  const eventObj = {};

  events.forEach((event) => {
    if (event?.uiFaceDetection) {
      eventObj[event.event_id] = {
        avatar: event.uiFaceDetection.uiSampleURL,
        type: 'event',
      };
    } else if (event?.uiBodyDetection) {
      eventObj[event.event_id] = {
        avatar: event.uiBodyDetection.uiSampleURL,
        type: 'body',
      };
    }
  });
  return eventObj;
}
